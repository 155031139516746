import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Button,
  InputField,
  SelectField,
  CheckboxField,
} from 'hult-toolkit';
import {
  StyledAddPayerContainer,
  StyledAddPayer,
  StyledWidth,
  StyledTop,
  StyledPayerInfo,
  StyledNameInput,
  StyledCheckboxContainer,
} from './addPayer.styles';
import { ISbStoryData } from '@storyblok/react';
import {useCompleteApplicationStepMutation, useGetApplicationMultiStepQuery, useGetApplicationPayersQuery, useUpdateApplicationPayerMutation} from '../../graphql';
import {useAppContext, useAppDispatch} from '../../context/AppContext';
import { useQuery } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { IPayerInfo } from '../QuestionInputs/PayerManagement/payerManagement.types';
import { transformMarkdown } from '../../utils/markdownHelper';

interface IAddPayerCmp {
  blok: ISbStoryData & {
    consent_title: string;
    consent_desc: string;
  };
}

export interface IParentInfo {
  parentRelationship: string;
  parentFirstName: string;
  parentLastName: string;
  parentEmail: string;
  parentMobileNumber: string;
}

export const AddPayerCmp: React.FC<IAddPayerCmp> = ({
  blok
}) => {
  const [selectedRecordObject, setSelectedRecordObject] = useState<IParentInfo | null>(null);
  const [fieldValidities, setFieldValidities] = useState<{ [key: string]: boolean }>({
    "payerEmail": false,
    "payerFirstName": false,
    "payerLastName": false,
    "payerMobileNumber": false,
    "payerRelationship": false
  });
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});
  const [consentChecked, setConsentChecked] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [records, setRecords] = useState<IParentInfo[]>([]);
  const [payers, setPayers] = useState<IPayerInfo[]>([]);
  const [updateApplicationPayer] = useUpdateApplicationPayerMutation();
  const query = useQuery();
  const navigate = useNavigate();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation()
  const dispatch = useAppDispatch();
  const {selectedApplication, email } = useAppContext();
  const consentRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    const allFieldsValid = Object.values(fieldValidities).every(valid => valid);
    const isEmailValid = fieldValues.payerEmail !== email;
    if (allFieldsValid && consentChecked && isEmailValid) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [fieldValidities, consentChecked, fieldValues.payerEmail, email])

  const handleSelectRecord = (selectedOption: any) => {
    const parsedRecord = JSON.parse(selectedOption.value);
    setSelectedRecordObject(parsedRecord);
  };

  const handleFieldChange = (name: string, value: string | undefined, valid: boolean) => {
    setFieldValues(prevValues => ({
      ...prevValues,
      [name]: value || '',
    }));
    setFieldValidities(prevValidities => ({
      ...prevValidities,
      [name]: valid,
    }));
  };

  const handleConsentChange = useCallback((selectedValue: { name: string; value: string | undefined; valid: boolean; required: boolean; }) => {
    setConsentChecked(selectedValue.valid);
    setFieldValues(prevValues => ({
      ...prevValues,
      consent: selectedValue.valid ? 'agreed' : '',
    }));
  }, []);

  const handleSubmit = () => {
    setIsLoading(true)
    const stepId = query.get('editing')
    if (stepId && selectedApplication){
      updateApplicationPayer({
        variables: {
          guid: selectedApplication,
          step: stepId,
          payerEmail: fieldValues.payerEmail,
          revokeAccess: false,
          ...fieldValues
        },
        onCompleted(){
          completeStep()
        },
        onError(error){
          console.log("updateApplicationPayer error", error)
        }
      });
    }
  };              

  useGetApplicationMultiStepQuery({
    variables: {
      guid: selectedApplication as string,
      step: "parent"
    },
    fetchPolicy: 'cache-and-network',
    skip: !selectedApplication,
    onCompleted({ getApplicationMultiStep }) {
      setRecords(getApplicationMultiStep as IParentInfo[] || []);
    },
    onError(err){
      console.log("useGetApplicationMultiStepQuery error", err)
    }
  });

  if (selectedApplication){
    useGetApplicationPayersQuery({
      variables: {
        guid: selectedApplication
      },
      fetchPolicy: 'cache-and-network',
      onCompleted({getApplicationPayers}) {
        setPayers(getApplicationPayers as IPayerInfo[] || [])
      },
      onError(error){
        console.error("useGetApplicationPayersQuery error", {error})
      }
    })
  }

  const completeStep = async () => {
    const stepId = query.get('editing') || undefined
    try {  
      await completeApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: stepId || '',
        },
        onCompleted(data) {
          dispatch({
            type: 'complete-step',
            payload: {
              guid: selectedApplication || '',
              step: stepId,
            },
          });
        },
        onError(error) {
          console.error("completeApplicationStepMutation error", error);
        },
      });

      setIsLoading(false);
  
      navigate(-1);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (consentRef.current) {
      consentRef.current.innerHTML = transformMarkdown(blok.consent_desc).replace(
        /<\/?span[^>]*>/g,
        ''
      );
    }
  }, [blok.consent_desc]);


  return (
    <StyledAddPayerContainer>
      <StyledAddPayer>
        
        <StyledWidth>    
          <StyledTop>
            <h1>Recipient information</h1>
            <p>
              I authorize Hult International Business School to disclose my
              personal data to the following:
            </p>
            <SelectField
              label="Select Existing Record"
              name="select-existing-record"
              onChange={selectedOption => handleSelectRecord(selectedOption)}
              options={records
                // Filter out records that already exist in the payers array
                .filter(record => !payers.some(payer => payer.payerEmail === record.parentEmail))
                .map(record => ({
                  name: `${record.parentFirstName} ${record.parentLastName}`,
                  value: JSON.stringify(record),
                }))
              }
              placeholder="Select existing record"
              show_validation
            />
          </StyledTop>
          <StyledPayerInfo>
            <SelectField
              key={
                selectedRecordObject
                  ? selectedRecordObject.parentRelationship
                  : 'Relationship'
              }
              label="Relationship"
              name="payerRelationship"
              onChange={selectedValue => {
                handleFieldChange(selectedValue.name, selectedValue.value, selectedValue.valid)
              }}
              options={[
                {
                  name: 'Parent/Guardian',
                  value: 'Parent/Guardian',
                },
                {
                  name: 'Individual Sponsor',
                  value: 'Individual Sponsor',
                },
              ]}
              default_value={
                selectedRecordObject && selectedRecordObject.parentRelationship
                  ? selectedRecordObject.parentRelationship
                  : ''
              }
              placeholder="Relationship"
              show_validation
              required
            />
            <StyledNameInput>
              <InputField
                label="First name"
                key={
                  selectedRecordObject
                    ? selectedRecordObject.parentFirstName
                    : 'First name'
                }
                default_value={
                  (selectedRecordObject && selectedRecordObject.parentFirstName) || ''
                }
                min_length={2}
                name="payerFirstName"
                onChange={selectedValue => {
                  handleFieldChange(selectedValue.name, selectedValue.value, selectedValue.valid)
                }}
                placeholder="First name"
                type="text"
                required
              />
              <InputField
                label="Last name"
                key={
                  selectedRecordObject
                    ? selectedRecordObject.parentLastName
                    : 'Last name'
                }
                default_value={
                  (selectedRecordObject && selectedRecordObject.parentLastName) || ''
                }
                min_length={2}
                name="payerLastName"
                onChange={selectedValue => {
                  handleFieldChange(selectedValue.name, selectedValue.value, selectedValue.valid)
                }}
                placeholder="Last name"
                type="text"
                required
              />
            </StyledNameInput>
            <InputField
              label="Email"
              key={selectedRecordObject ? selectedRecordObject.parentEmail : 'Email'}
              default_value={
                (selectedRecordObject && selectedRecordObject.parentEmail) || ''
              }
              min_length={2}
              name="payerEmail"
              onChange={selectedValue => {
                handleFieldChange(selectedValue.name, selectedValue.value, selectedValue.valid)
              }}
              placeholder="Email address"
              type="email"
              required
              invalid={fieldValues.payerEmail === email}
              show_validation={fieldValues.payerEmail !== email}
              error_message={fieldValues.payerEmail === email ? "You can't use your own email address" : "Invalid email address"}
            />
            <InputField
              key={
                selectedRecordObject
                  ? selectedRecordObject.parentMobileNumber
                  : 'Phone number'
              }
              default_value={
                (selectedRecordObject && selectedRecordObject.parentMobileNumber) || ''
              }
              label="Phone number (including country code)"
              min_length={2}
              name="payerMobileNumber"
              onChange={selectedValue => {
                handleFieldChange(selectedValue.name, selectedValue.value, selectedValue.valid)
              }}
              placeholder="e.g +441234567890"
              required
              show_validation
              type="tel"
            />
          </StyledPayerInfo>
          <StyledCheckboxContainer>
            {blok.consent_title && <h1>{blok.consent_title}</h1>}
            {blok.consent_desc && <p ref={consentRef}></p>}
            <CheckboxField
              name="consent"
              id="consent"
              label="I have read and agree to the above statement"
              onChange={handleConsentChange}
              required
            />
          </StyledCheckboxContainer>

          <Button
            label="Submit"
            icon={{
              animate: loading ? "spin" : "",
              library: 'solid',
              name: loading ? "spinner" : "arrow-right",
            }}
            Wrapper="button"
            variant={loading || !isValid ? "disabled" : "primary"}
            WrapperProps={{
              onClick: handleSubmit,
            }}
          />
        </StyledWidth>
         
      </StyledAddPayer>
    </StyledAddPayerContainer>
  );
};
