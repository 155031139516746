import { AlertSa } from "hult-toolkit";
import { useState, useEffect } from "react";
import { useSettingsContext } from "../../../context/SettingsContext";
import { IOptions } from "../../../data/types";
import { getDatasourceEntries } from "../../../services/storyblok";
import { variantMapping } from "../../../utils/variantMapping";
import { LoadingInput } from "../TextInput/TextInput.helpers";
import { IMultiLookUp } from "./MultiLookup.types";
import { MultiLookupUi } from "./MultiLookupUi";


export const MultiLookUpInput = ({
  datasource,
  stepRecord,
  questionMapping,
  handleChange,
}: IMultiLookUp) => {
  const defaultValue = stepRecord?.[questionMapping] || '';

  const [dsOptions, setDsOptions] = useState<IOptions[] | undefined>([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const [submitObj, setSubmitObj] = useState({
    value: [],
    valid: false,
  });

  const { errorMessage } = useSettingsContext();

  function multiLookupEdgeCases(inputData: IOptions[]) {
    if (questionMapping === 'allLanguages') {
      const languageStrings = defaultValue.split(';');
      const languageObjects = languageStrings.map((language: string) => {
        return inputData.find(({ label }: IOptions) => label === language);
      });
      setSelectedOptions(defaultValue === '' ? [] : languageObjects);

      return true;
    }

    return false;
  }

  useEffect(() => {
    if (datasource)
      getDatasourceEntries(datasource || '')
        .then(data => {
          if (!multiLookupEdgeCases(data)) {
            setSelectedOptions(defaultValue);
          }
          setDsOptions(data);
          setOptionsLoaded(true);
        })
        .catch(error => {
          console.error(error);
          const storyblokError = errorMessage?.desc?.content[0].content[0].text;
          return (
            <>
              {errorMessage && (
                <AlertSa
                  desc={storyblokError}
                  icon={variantMapping[errorMessage.variant].icon}
                  icon_color={variantMapping[errorMessage?.variant].icon_color}
                  title={errorMessage?.title || ''}
                />
              )}
            </>
          );
        });
  }, [datasource]);

  useEffect(() => {
    const submitVal = () => {
      if (questionMapping === 'allLanguages') {
        if (submitObj?.value.length > 0) {
          return submitObj.value.map(({ value }) => value).join(';');
        }
        return '';
      }
    };

    handleChange({
      value: submitVal(),
      valid: submitObj.valid,
    });
  }, [submitObj]);

  if (!optionsLoaded) {
    return <LoadingInput />;
  }

  const inputChange = (e: { value: never[]; valid: boolean }) => {
    setSubmitObj(e);
  };

  return (
    <MultiLookupUi
      options={dsOptions}
      selected={selectedOptions}
      handleChange={inputChange}
    />
  );
};
