import { useEffect, useState } from 'react';
import { AddressUI } from './AddressUI';
import { isValidJSON } from '../QuestionInputHelpers';
import { IEvent, IQuestionProps } from '../../QuestionForm/questionForm.types';
import { NewDatasourceFetcher } from '../../../services/storyblok';

const emptyAddress = {
  country: '',
  address1: '',
  address2: '',
  postalCode: '',
  city: '',
  state: '',
};

export const AddressInput = ({
  handleChange,
  stepRecord,
  questionMapping,
}: IQuestionProps) => {
  const savedRecord = stepRecord?.[questionMapping];

  const defaultAddress = () => {
    if (savedRecord === null || isValidJSON(savedRecord) === false) {
      return emptyAddress;
    }

    return JSON.parse(savedRecord);
  };
  
  const [addressObj, setAddressObj] = useState(defaultAddress());
  const [addressValid, setAddressValid] = useState(savedRecord !== null);
  const { objects } = NewDatasourceFetcher("country-lookup")

  useEffect(() => {
    handleChange({
      value: JSON.stringify(addressObj),
      valid: addressValid,
    });
  }, [addressObj]);
  
  const countryObjects = objects?.map((obj) => {
    obj.name = obj.label
    return {
      name: obj.label,
      value: obj.value
    }
  })

  return (
    <AddressUI
      countryOptions={countryObjects}
      defaultValue={addressObj}
      handleChange={(e: IEvent) => {
        setAddressObj(e.value);
        setAddressValid(e.valid);
      }}
    />
  );
};
