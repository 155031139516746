import styled from 'styled-components';
import {StyledIcon} from '../../icon/icon.styles';

interface IStyledPicklistItem {
  selected?: boolean;
}

export const StyledPicklist = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 8px;
  position: relative;
  padding: 8px;
  background-color: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.darkGrey};
  border-radius: 4px;

  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    width: 100%;
  }
`;
export const StyledPicklistItem = styled.div<IStyledPicklistItem>`
  transition: 0.2s;
  cursor: pointer;
  padding: 16px;
  width: 100%;
  display: flex;
  user-select: none;
  border-radius: 4px;
  border: 2px solid;
  border-color: ${({selected}) =>
    selected
      ? props => props.theme.colors.green
      : props => props.theme.colors.darkGrey};
  background: ${props => props.theme.colors.white};
  ${props => props.theme.mixins.body};
  color: ${({selected}) =>
    selected
      ? props => props.theme.colors.green
      : props => props.theme.colors.darkGrey};

  & ${StyledIcon} {
    margin-right: 8px;
  }
`;
