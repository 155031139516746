import { useEffect, useState, FC } from 'react';
import {
  StyledInputWrapper,
  StyledLabel,
} from '../../ui/inputs/inputs.styles';
import {
  StyledPicklist,
  StyledPicklistItem,
} from '../../ui/inputs/picklist/picklist.styles';
import { Icon } from '../../ui/icon/icon';
import { IPicklistUI } from './PickList.types';
import { isSameValue } from '../QuestionInputHelpers';

export const PicklistUI: FC<IPicklistUI> = ({
  label,
  selectedValue,
  options = [],
  onChange = () => { },
}) => {

  const correctName = options.find(item =>
    isSameValue(item.value, selectedValue)
  )?.label;

  const [isSelected, setIsSelected] = useState<string | undefined>(
    selectedValue
  );
  const [selectedName, setSelectedName] = useState<string | undefined>(correctName);

  useEffect(() => {
    setIsSelected(selectedValue);
    setSelectedName(correctName);
  }, [options]);


  useEffect(() => {
    const isValid =
      options.find(({ value }) => value === isSelected) !== undefined;

    onChange({
      value: isSelected,
      valid: isValid,
      name: selectedName,
    });
  }, [isSelected]);

  return (
    <StyledInputWrapper data-testid="picklist">
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledPicklist>
        {options.map((item, idx) => {
          const selectLogic = isSameValue(item.value, isSelected);

          return (
            <StyledPicklistItem
              data-testid="picklist-item"
              key={`picklist-item-${idx}-${isSelected}`}
              onClick={() => {
                setIsSelected(item.value);
                setSelectedName(item.label);
              }}
              selected={selectLogic}
            >
              <Icon icon={selectLogic ? 'circle-check' : 'circle'} />
              {item.label}
            </StyledPicklistItem>
          );
        })}
      </StyledPicklist>
    </StyledInputWrapper>
  );
};
