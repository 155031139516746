export const isValidDate = (
  month: string,
  day: string,
  year: string,
  maxYear: number = 2100,
  minYear: number = 1900
) => {
  const monthInt = Number(month);
  const dayInt = Number(day);

  if (Number(year) > maxYear || Number(year) < minYear) {
    return false;
  }

  var d = new Date(Number(year), monthInt - 1, dayInt);

  return monthInt === d.getMonth() + 1;
};
