import { useEffect, useState } from 'react';
import { IEventTarget, IQuestionProps } from '../../QuestionForm/questionForm.types';
import { checkValidNumber } from '../QuestionInputHelpers';
import { TextInput } from '../TextInput';

export const TelephoneInput = ({
  handleChange,
  stepRecord,
  questionMapping,
}: IQuestionProps) => {
  const savedValue = stepRecord?.[questionMapping] || '';

  const [phoneNumber, setPhoneNumber] = useState(savedValue);
  const [phoneNumberValid, setPhoneNumberValid] = useState(
    checkValidNumber(savedValue)
  );

  useEffect(() => {
    handleChange({
      value: phoneNumber,
      valid: phoneNumberValid,
    });
  }, [phoneNumber]);

  const changeNumber = (e: IEventTarget) => {
    const inputVal = e?.target?.value;
    setPhoneNumber(inputVal);
    setPhoneNumberValid(e?.target?.value !== '' && checkValidNumber(inputVal));
  };

  return (
    <TextInput
      type="telephone"
      value={phoneNumber}
      onChange={changeNumber}
      valid={phoneNumberValid}
    />
  );
};
