import { FC, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ISchoolLookUp } from './SchoolLookUp.types';
import { isValidJSON } from '../QuestionInputHelpers';
import { StyledInput } from '../../ui/inputs/inputs.styles';
import {
  StyledLookup,
  StyledResults,
  StyledSelected,
  StyledLoading,
} from './SchoolLookUp.styles';
import { Icon } from '../../ui/icon/icon';

const emptySchoolObj = {
  schoolName: '',
  schoolValue: '',
};

export const SchoolLookUp: FC<ISchoolLookUp> = ({
  handleChange,
  stepRecord,
  questionMapping,
  datasource,
}) => {
  const savedSchool = stepRecord?.[questionMapping] || '';

  const defaultSchoolObj = isValidJSON(savedSchool)
    ? JSON.parse(savedSchool)
    : emptySchoolObj;

  const [schoolObj, setSchoolObj] = useState(defaultSchoolObj);
  const [validSchool, setValidSchool] = useState(false);

  const defaultObj = {
    name: schoolObj.schoolName,
    value: schoolObj.schoolValue,
    valid: schoolObj.schoolName !== '' && schoolObj.schoolValue !== '',
  };

  // const htmlId = uniqueId("id")
  const [titleFilter, setTitleFilter] = useState<string>(
    defaultObj ? defaultObj.name : ''
  );
  const [exactMatch, setExactMatch] = useState<boolean>(
    defaultObj && defaultObj.name.length > 1 ? true : false
  );
  const [fetching, setFetching] = useState(false);
  const [result, setResult] = useState<Array<any>>();
  const [optionsVisible, setOptionsVisible] = useState(false);

  useEffect(() => {
    const res = {
      value: JSON.stringify(schoolObj),
      valid: validSchool,
    };

    handleChange(res);
  }, [schoolObj, validSchool]);

  useEffect(() => {
    setSchoolObj(defaultSchoolObj);
  }, [savedSchool]);

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOptionsVisible(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const handleSearch = async () => {
    try {
      setFetching(true);
      const response = await axios.get(
        `https://api.storyblok.com/v2/cdn/stories?starts_with=${datasource}&per_page=100&token=3KVgjgufKKhZ10JOH2es8wtt&version=draft&search_term=${titleFilter}&cv=2`
      );

      if (response) {
        const results = response.data.stories
          .map((school: any) => school.content)
          .filter(
            (content: any) =>
              content.locale_name.includes(titleFilter) ||
              content.name.toLowerCase().includes(titleFilter)
          );

        const sorted1 = results.sort((a: any, b: any) => {
          const aName: string = a.name.toLowerCase();
          const bName: string = b.name.toLowerCase();
          if (aName.indexOf(titleFilter) < bName.indexOf(titleFilter))
            return -1;
          if (aName.indexOf(titleFilter) > bName.indexOf(titleFilter)) return 1;

          if (aName < bName) return -1;
          if (aName > bName) return 1;

          return 0;
        });
        setOptionsVisible(true);
        setResult(sorted1);
      }
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setFetching(false);
    }
  };
  useEffect(() => {
    handleSearch();
    if (titleFilter.length < 1) {
      setValidSchool(false);
    }
  }, [titleFilter]);

  return (
    <StyledLookup>
      {exactMatch ? (
        <StyledSelected
          onClick={() => {
            setTitleFilter('');
            setExactMatch(false);
            setValidSchool(false);
          }}
        >
          <div>
            <Icon icon={'circle-check'} />
            <span>{titleFilter}</span>
          </div>
          <Icon icon={'pencil'} className="fa-bounce" />
        </StyledSelected>
      ) : (
        <>
          <StyledInput>
            {fetching && (
              <StyledLoading>
                <Icon icon={'spinner'} className={'fa-spin'} />
              </StyledLoading>
            )}
            <input
              placeholder={'Start typing to search...'}
              autoComplete="off"
              value={titleFilter}
              onChange={(e: any) => {
                setTitleFilter(e.target.value);
                if (e.target.value.length > 0) {
                  setValidSchool(true);
                  setSchoolObj({
                    schoolName: e.target.value,
                    schoolValue: e.target.value,
                  });
                } else {
                  setValidSchool(true);
                }
              }}
            />
          </StyledInput>
          <>
            {result &&
              result.length > 0 &&
              titleFilter.length > 1 &&
              !exactMatch &&
              optionsVisible && (
                <StyledResults ref={wrapperRef}>
                  <>
                    {result.map(
                      option =>
                        option.name !== titleFilter && (
                          <div
                            key={option.id}
                            onClick={() => {
                              setTitleFilter(option.locale_name);
                              setExactMatch(true);
                              setSchoolObj({
                                schoolName: option.name,
                                schoolValue: option.id,
                              });
                              setValidSchool(true);
                            }}
                          >
                            <span>{option.locale_name}</span>
                          </div>
                        )
                    )}
                  </>
                </StyledResults>
              )}
          </>
        </>
      )}
    </StyledLookup>
  );
};
