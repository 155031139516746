import styled from 'styled-components';
import {StyledIcon} from '../icon/icon.styles';

export interface IStyledBanner {
  image?: string;
}

export interface IStyledTag {
  color?: 'blue' | 'green' | 'red';
}

export const StyledTag = styled.div<IStyledTag>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 2px;
  background: ${props => props.color === 'blue' && `#0C5B9E`};
  background: ${props => props.color === 'green' && `#0E6D63`};
  background: ${props => props.color === 'red' && `#AB050F`};

  & p {
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.circularMedium};
    font-size: 14px;
  }

  ${StyledIcon} {
    color: ${props => props.theme.colors.white};
  }
`;

export const StyledBanner = styled.div`
  position: relative;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 0.2px 1.9px rgba(0, 0, 0, 0.01),
    0px 0.4px 4.3px rgba(0, 0, 0, 0.015), 0px 0.8px 7.7px rgba(0, 0, 0, 0.018),
    0px 1.3px 12.8px rgba(0, 0, 0, 0.022), 0px 2.1px 21.2px rgba(0, 0, 0, 0.025),
    0px 3.7px 37px rgba(0, 0, 0, 0.03), 0px 8px 80px rgba(0, 0, 0, 0.04);

  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding: 32px;
  }
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    padding: 24px;
  }
`;

export const StyledBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  & h1 {
    ${props => props.theme.mixins.heading};
    font-size: 24px;
    line-height: 32px;

    @media (max-width: ${props => props.theme.breakPoints.tablet}) {
      max-width: 70%;
    }
  }
`;

export const StyledBannerDesc = styled.p`
  max-width: 70%;

  @media (max-width: ${props => props.theme.breakPoints.mobile}) {
    max-width: 100%;
  }
`;

export const StyledBannerButtons = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 24px;
  flex-wrap: wrap;
`;

export const StyledBackgroundImage = styled.div<IStyledBanner>`
  ${props =>
    props.image &&
    `
      background-image: url(${props.image});
      background-repeat: no-repeat;
      background-position: right top;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 0;
      `}

  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    ${props => props.image && `background-size: 40%;`}
  }

  @media (max-width: ${props => props.theme.breakPoints.mobile}) {
    ${props => props.image && `background-size: 46%;`}
  }
`;
