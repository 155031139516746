import styled from 'styled-components';
import {StyledIcon} from '../icon/icon.styles';

export interface IStyledButton {
  variant: string;
  icon_only: boolean;
  icon_reverse: boolean;
  mini: boolean;
  hidden?: boolean;
}

export const StyledButton = styled.div<IStyledButton>`
  display: flex;

  ${props => props.hidden && `display: none;`}

  & > div,
  & > a,
  & > button {
    border-radius: 50px;
    border: solid 2px transparent;
    cursor: pointer;
    padding: 12px 24px;
    align-items: center;
    ${props => props.theme.mixins.bold}
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${props => props.icon_reverse && `flex-direction: row-reverse;`}

    ${props =>
      props.icon_only &&
      `
      width: 48px;
      height: 48px;
      min-width: 48px;
      max-width: 48px;
      justify-content: center;
      padding: 12px 0;
      `}
    ${props =>
      props.variant === 'loading' &&
      `
      ${props.theme.mixins.keyframeAnimation}
      ${props.theme.mixins.elementAnimation}
      border: 2px solid ${props.theme.colors.black5};
  
      & span{
        opacity: 0;
      }
    `}
    ${props =>
      props.variant === 'primary' &&
      `
      background: rgb(81,69,250);
      background: linear-gradient(180deg, ${props.theme.colors.lightBlue} 0%, ${props.theme.colors.blue} 100%);
      box-shadow: 0px 0.2px 1.9px rgba(0, 0, 0, 0.01),
      0px 0.4px 4.3px rgba(0, 0, 0, 0.015), 0px 0.8px 7.7px rgba(0, 0, 0, 0.018),
      0px 1.3px 12.8px rgba(0, 0, 0, 0.022), 0px 2.1px 21.2px rgba(0, 0, 0, 0.025),
      0px 3.7px 37px rgba(0, 0, 0, 0.03), 0px 8px 80px rgba(0, 0, 0, 0.04);
      color: ${props.theme.colors.white};
      `}

    ${props =>
      props.variant === 'secondary' &&
      `
      background: transparent;
      border-color: ${props.theme.colors.grey};
      `}

    ${props =>
      props.variant === 'text-only' &&
      `
      background: transparent;
      border: none;
      border-bottom: solid ${props.theme.colors.grey} 2px;
      border-radius: 0;
      padding: 0 0 8px 0;
      @media (max-width: ${props.theme.breakPoints.tablet}) {
        padding: 0 0 4px 0;
      }
      `}

    ${props =>
      props.variant === 'disabled' &&
      `
      background: ${props.theme.colors.black5};
      cursor: auto;
      color: ${props.theme.colors.black20};
      `}
  & ${StyledIcon} {
      ${props =>
        !props.icon_only &&
        `
      margin-left: 16px;
    `}

      ${props =>
        props.icon_reverse &&
        `
      margin-left: 0; 
      margin-right: 16px;`}
    }
  }

  ${props =>
    props.mini &&
    `
  & > div,
  & > a,
  & > button {
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: ${props.theme.fonts.circularBook};

    & ${StyledIcon} {
      ${
        !props.icon_only &&
        `
      margin-left: 12px;
    `
      }
  
      ${
        props.icon_reverse &&
        `
      margin-left: 0; 
      margin-right: 12px;`
      }
    }
    ${
      props.variant === 'text-only' &&
      `
    padding: 0 0 8px 0;
    `
    }
  }


    `}
`;
