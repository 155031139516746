import styled from 'styled-components';

export interface IStyledCard {
  border_color?: string;
  border_position_left: boolean;
  hover: boolean;
  padding: boolean;
  loading?: boolean;
}

export const StyledCard = styled.div<IStyledCard>`
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 0.2px 1.9px rgba(0, 0, 0, 0.01),
    0px 0.4px 4.3px rgba(0, 0, 0, 0.015), 0px 0.8px 7.7px rgba(0, 0, 0, 0.018),
    0px 1.3px 12.8px rgba(0, 0, 0, 0.022), 0px 2.1px 21.2px rgba(0, 0, 0, 0.025),
    0px 3.7px 37px rgba(0, 0, 0, 0.03), 0px 8px 80px rgba(0, 0, 0, 0.04);
  @media (min-width: ${props => props.theme.breakPoints.tablet}) {
    padding: ${props => (props.padding ? '32px' : '0')};
    display: flex;
    align-items: flex-end;
  }
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    padding: ${props => (props.padding ? '24px' : '0')};
  }
  ${props =>
    props.border_color &&
    `
    ${
      props.border_position_left
        ? `
      border: 0;
      border-left: 6px solid ${props.border_color};
    `
        : `
      border: 0;
      border-top: 6px solid ${props.border_color};
    `
    }
  `}
  ${props =>
    props.hover &&
    `
    transition: all 0.2s ease 0s;

    &:hover {
      transform: translateY(-8px);
      box-shadow: 0 16px 16px rgb(44 44 44 / 15%);
    }
  `}
  ${props =>
    props.loading &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
    box-shadow: none;
  `}
`;
