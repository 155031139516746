import React from 'react';
import {useAppContext} from '../../context/AppContext';

import {
  StyledAccount,
  StyledTitle,
  StyledInformationContainer,
  StyledInformation,
  StyledData,
  StyledSubTitle,
} from './accountInfo.styles';

export const AccountInfo: React.FC = () => {
  const {user} = useAppContext();

  return (
    <StyledAccount>
      <StyledTitle>Your Information</StyledTitle>
      {user && (
        <StyledInformationContainer>
          {user.studentId && (
            <StyledInformation>
              <StyledSubTitle>Student ID</StyledSubTitle>
              <StyledData>{user.studentId}</StyledData>
            </StyledInformation>
          )}
          {(user.firstName || user.lastName) && (
            <StyledInformation>
              <StyledSubTitle>Name</StyledSubTitle>
              <StyledData>
                {user.firstName} {user.lastName}
              </StyledData>
            </StyledInformation>
          )}
          {user.email && (
            <StyledInformation>
              <StyledSubTitle>Email</StyledSubTitle>
              <StyledData>{user.email}</StyledData>
            </StyledInformation>
          )}
        </StyledInformationContainer>
      )}
    </StyledAccount>
  );
};
