import { useState, useEffect, FC } from 'react';
import {
  StyledInput,
  StyledInputWrapper,
} from '../../ui/inputs/inputs.styles';
import {
  StyledDropDown,
  StyledLookup,
  StyledOption,
  StyledSelectContainer,
  StyledSelected,
  StyledSelectedWrapper,
} from '../../ui/inputs/lookup/lookup.styles';
import { StyledIcon } from '../../MultiRecordSteps/multiRecordSteps.styles';
import { Icon } from '../../ui/icon/icon';
import { IMultiLookupUi } from './MultiLookup.types';
import { IOptions } from '../../ui/inputs/inputs.types';

export const MultiLookupUi: FC<IMultiLookupUi> = ({
  options,
  selected,
  handleChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selected || []);
  const [titleFilter, setTitleFilter] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<
    IOptions[] | undefined
  >([]);

  useEffect(() => {
    if (titleFilter !== '') {
      const res = options?.filter(({ label }) =>
        label.toLowerCase().includes(titleFilter.toLowerCase())
      );
      setFilteredOptions(res);
    }
  }, [titleFilter]);

  useEffect(() => {
    handleChange({
      value: selectedOptions,
      valid: selectedOptions.length > 0,
    });
  }, [selectedOptions]);

  return (
    <StyledInputWrapper>
      <StyledLookup>
        <StyledSelectedWrapper>
          <StyledSelected>
            {selectedOptions &&
              selectedOptions?.length > 0 &&
              selectedOptions.map((i, index) => (
                <StyledSelectContainer key={index}>
                  <p>{i?.label}</p>
                  <StyledIcon
                    onClick={() => {
                      setSelectedOptions(prev =>
                        prev?.filter(obj => i?.label !== obj?.label)
                      );
                    }}
                  >
                    <Icon icon="xmark" library={'fa-regular'} />
                  </StyledIcon>
                </StyledSelectContainer>
              ))}
          </StyledSelected>
        </StyledSelectedWrapper>
        <StyledInput>
          <input
            value={titleFilter}
            onChange={event => {
              const { value } = event.target;
              setTitleFilter(value);
            }}
          />
        </StyledInput>
        {filteredOptions && filteredOptions.length > 0 && (
          <StyledDropDown>
            {filteredOptions?.map((s, idx) => (
              <StyledOption
                key={`lookup-option-${idx}`}
                onClick={() => {
                  if (selectedOptions.length > 0) {
                    setSelectedOptions([...selectedOptions, s]);
                  } else {
                    setSelectedOptions([s]);
                  }

                  setFilteredOptions([]);
                  setTitleFilter('');
                }}
              >
                {s.label}
              </StyledOption>
            ))}
          </StyledDropDown>
        )}
      </StyledLookup>
    </StyledInputWrapper>
  );
};
