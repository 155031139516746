import {HTMLAttributes} from 'react';
import {IQuestionFormButton} from './questionForm.types';
import { Button } from 'hult-toolkit';

export const QuestionFormButton: React.FC<IQuestionFormButton> = ({
  loading,
  saving = false,
  disabled = false,
  onClick,
}) => {
  if (loading) {
    return (
      <Button
        icon={{
          animate: 'spin',
          library: 'solid',
          name: 'spinner',
        }}
        label="Loading"
        size="small"
        variant="disabled"
      />
    );
  }

  return (
    <>
      <Button
        Wrapper="button"
        label="Continue"
        icon={{
          animate: saving ? 'spin' : '',
          library: 'solid',
          name: saving ? 'spinner' : 'arrow-right',
        }}
        WrapperProps={
          {
            onClick: () => {
              if (!saving && !disabled && onClick) onClick();
            },
            disabled: disabled || saving,
          } as HTMLAttributes<HTMLButtonElement>
        }
        variant={disabled || saving ? 'disabled' : 'primary'}
        hidden={saving}
      />
    </>
  );
};
