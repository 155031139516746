import {inputTypes} from './input.types';

export const inputPattern = (type?: inputTypes) => {
  switch (type) {
    case 'email':
      return /^[\\p{L}/gu0-9.!#$%&+'^_`{}~-]+@[\\p{L}/gu0-9-]+[.]+(?:.[\\p{L}/gu0-9-]+)*$/;
    case 'telephone':
      return /^\\+[0-9]{6,20}/;
    case 'url':
      return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    default:
      return /^[^()/><{}~;]*$/;
  }
};

export const emptyStrChecker = (str: string) => {
  return /^\s*$/.test(str);
};
