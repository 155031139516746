import React from 'react';
import {IButton} from './button.types';
import {StyledButton} from './button.styles';
import {Icon} from '../icon/icon';

export const Button: React.FC<IButton> = ({
  Wrapper = 'button',
  WrapperProps,
  label,
  icon,
  icon_library,
  variant = 'primary',
  icon_reverse = false,
  mini = false,
  hidden = false,
}) => {
  return (
    <StyledButton
      icon_only={label ? false : true}
      variant={variant}
      icon_reverse={icon_reverse}
      mini={mini}
      hidden={hidden}
    >
      <Wrapper {...WrapperProps}>
        {label && <span>{label}</span>}
        {icon && <Icon library={icon_library} icon={icon} />}
      </Wrapper>
    </StyledButton>
  );
};
