import { useEffect, useState } from 'react';
import {
  storyblokInit,
  apiPlugin,
  getStoryblokApi,
  ISbResult,
} from '@storyblok/react';
import { IDictionary } from '../../data/types';
import { Page } from '../../components/Page';
import { Root } from '../../components/Root';
import { Hero } from '../../components/Hero';
import { Step } from '../../components/Steps';
import { IOptions } from '../../components/ui/inputs/inputs.types';
import { SubmitApplication } from '../../components/SubmitApplication';
import { AlertWrapper } from '../../components/ui/alertWrapper';
import { BannerWrapper } from '../../components/ui/bannerWrapper';
import { ProgressBar } from '../../components/ProgressBar';
import { ApplicationHeader } from '../../components/ApplicationHeader';
import { AddPayerCmp } from '../../components/AddPayer';
import { CardResource } from '../../components/CardResource';
import { ApplicationsList } from '../../components/ApplicationsList'
import Section from '../../components/Section'

const LocalComponentList = {
  alert: AlertWrapper,
  // notification: SubmitApplication,
  page: Page,
  root: Root,
  'hult-hero': Hero,
  step: Step,
  submitapplication: SubmitApplication,
  banner: BannerWrapper,
  'progress-bar': ProgressBar,
  'application-header': ApplicationHeader,
  'add-payer': AddPayerCmp,
  'card-resource': CardResource,
  'applications-list': ApplicationsList,
  section: Section,
};

const ComponentList: IDictionary<React.FC<any>> = {
  ...LocalComponentList,
  // ...SbComponentList,
};

const initStoryblok = () =>
  storyblokInit({
    accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN,
    // bridge: false,
    apiOptions: {
      // storyblok-js-client config object
      cache: { type: 'memory' },
    },
    use: [apiPlugin],
    components: ComponentList,
  });

export interface IDatasourceEntry {
  id: string;
  name: string;
  value: string;
  dimension_value?: string;
}

interface IDatasourceResult extends ISbResult {
  data: { datasource_entries: IDatasourceEntry[] };
}

async function getDatasourceEntries(datasource: string, dimension?: string) {
  const storyblokApi = getStoryblokApi();
  let entries: IOptions[] = [],
    entriesCount = 0,
    page = 1,
    done = false;

  do {
    let {
      data: { datasource_entries },
      total,
    } = (await storyblokApi.get(`cdn/datasource_entries`, {
      version: (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') || 'published',
      datasource: datasource,
      per_page: 1000,
      dimension,
      page,
    })) as IDatasourceResult;

    entriesCount += datasource_entries.length;

    if (dimension) {
      entries = entries.concat(
        datasource_entries
          .filter(entry => entry.dimension_value)
          .map<IOptions>(({ name, dimension_value }) => {
            return { label: name, value: dimension_value as string };
          })
      );
    } else {
      entries = entries.concat(
        datasource_entries.map<IOptions>(({ name, value }) => {
          return { label: name, value };
        })
      );
    }

    if (entriesCount >= total) {
      done = true;
    }
    page++;
  } while (!done);

  return entries;
}

async function getRawDatasourceEntries(datasource: string, dimension?: string) {
  const storyblokApi = getStoryblokApi();
  let entries: IDatasourceEntry[] = [],
    entriesCount = 0,
    page = 1,
    done = false;

  do {
    let {
      data: { datasource_entries },
      total,
    } = (await storyblokApi.get(`cdn/datasource_entries`, {
      version: (process.env.REACT_APP_SB_VERSION as 'draft' | 'published') || 'published',
      datasource: datasource,
      per_page: 1000,
      dimension,
      page,
    })) as IDatasourceResult;

    entriesCount += datasource_entries.length;

    if (dimension) {
      entries = entries.concat(
        datasource_entries.filter(entry => entry.dimension_value)
      );
    } else {
      entries = entries.concat(datasource_entries);
    }

    if (entriesCount >= total) {
      done = true;
    }
    page++;
  } while (!done);

  return entries;
}

export const NewDatasourceFetcher = (datasource: any) => {

  const [dsOptions, setDsOptions] = useState<IOptions[] | undefined>([]);
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (datasource) {
      setOptionsLoaded(false);

      getDatasourceEntries(datasource)
        .then(data => {
          setDsOptions(data)
        })
        .then(() => {
          setOptionsLoaded(true);
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [datasource]);


  return {
    loading: !optionsLoaded,
    objects: dsOptions || []
  }
};

export { initStoryblok, getDatasourceEntries, getRawDatasourceEntries };
