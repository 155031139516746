import {useEffect, useState} from 'react';
import { TextInput} from '../TextInput';
import {IQuestionProps} from '../../QuestionForm/questionForm.types';
import {checkValidLinkedInUrl} from '../QuestionInputHelpers';

const linkedInStr = 'https://www.linkedin.com/in/';

export const LinkedInUrl = ({
  stepRecord,
  questionMapping,
  handleChange,
}: IQuestionProps) => {
  const defaultVal = stepRecord?.[questionMapping];

  const [urlValue, setUrlValue] = useState(defaultVal || linkedInStr);
  const [urlValid, setUrlValid] = useState<boolean | undefined>();

  useEffect(() => {
    const validUrl = checkValidLinkedInUrl(urlValue);

    handleChange({
      value: urlValue === linkedInStr ? '' : urlValue,
      valid: validUrl || false,
    });
  }, [urlValue]);

  return (
    <TextInput
      valid={urlValid}
      value={urlValue}
      onChange={e => {
        const inputVal = e.target.value;
        if (inputVal.includes(linkedInStr)) {
          setUrlValue(inputVal);
          setUrlValid(checkValidLinkedInUrl(inputVal));
        }
      }}
    />
  );
};
