import { Button } from 'hult-toolkit';
import { IBackButton } from '../QuestionForm/questionForm.types';

export const QuestionBackButton = ({onClick}: IBackButton) => {
  return (
    <Button
      Wrapper="button"
      WrapperProps={{
        onClick,
      }}
      label="Back"
      icon_reverse='true'
      variant="ghost"
      size="small"
      icon={{
        animate: '',
        library: 'solid',
        name: 'arrow-left',
      }}
    />
  );
};
