import React from "react";
import { IBreadcrumbWrapper } from "./breadcrumbWrapper.types";
import { StyledBreadcrumbs } from "./breadcrumbWrapper.styles";

export const BreadcrumbWrapper: React.FC<IBreadcrumbWrapper> = ({
  children,
}) => {
  return (
      <StyledBreadcrumbs>
        {children}
      </StyledBreadcrumbs>
  );
};