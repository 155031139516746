import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export const useQuery = () => {
  const {search} = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

interface IExtractedArgs {
  [x: string]: string;
}

export function useQueryController() {
  const location = useLocation();

  const cleanedQueryString = location.search.substring(1);
  const pattern = /([^&=]+)=([^&]+)/g;
  const extractedArgs: IExtractedArgs = {};

  const queries = cleanedQueryString.match(pattern);
  if (queries) {
    queries.forEach(query => {
      const queryArr = query.split('=');
      const queryKey = queryArr[0];
      const queryVal = queryArr[1];

      extractedArgs[queryKey] = queryVal;
    });
  }
  return extractedArgs;
}

export function defaultQuestionQuery() {
  let allQueries = useQueryController();

  if (!allQueries.hasOwnProperty('q')) {
    allQueries.q = '0';
  }
  return allQueries;
}

export function generalStepUrl(obj: Object) {
  const argumentStrings = Object.entries(obj).map(val => {
    return `${val[0]}=${val[1]}`;
  });

  return argumentStrings.join('&');
}
