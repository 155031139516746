import React from 'react';
import {
  StyledBanner,
  StyledBannerContent,
  StyledBannerDesc,
  StyledBannerButtons,
  StyledBackgroundImage,
  StyledTag,
} from './banner.styles';
import {Icon} from '../icon/icon';
import banner from '../../../assets/images/banner.png'
import {IBanner} from './banner.types';
import { Button } from 'hult-toolkit';

export const Banner: React.FC<IBanner> = ({
  tag_label,
  tag_icon,
  tag_color,
  title,
  desc,
  buttons,
}) => {
  return (
    <StyledBanner>
      <StyledBannerContent>
        <StyledTag color={tag_color}>
          {tag_icon && <Icon className="fas" icon={tag_icon} />}
          {tag_label && <p>{tag_label}</p>}
        </StyledTag>
        {title && <h1>{title}</h1>}
        {<StyledBannerDesc>{desc}</StyledBannerDesc>}
        <StyledBannerButtons>
          {buttons &&
            buttons.map((content: any, idx: any) => (
              <Button
                key={`hero-button-${idx}`}
                label={content.label}
                icon={{
                  animate: '',
                  library: 'solid',
                  name: content.icon,
                }}
                variant={content.variant}
                Wrapper={content.Wrapper}
                WrapperProps={content.WrapperProps}
              />
            ))}
        </StyledBannerButtons>
      </StyledBannerContent>
      <StyledBackgroundImage image={banner} />
    </StyledBanner>
  );
};
