import styled from 'styled-components';
import {Icon} from '../icon/icon';

interface IStyledInput {
  valid?: boolean;
  loading?: boolean;
  width?: string;
}

export const StyledInputWrapper = styled.div`
  display: ${({hidden}) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledLabel = styled.label<IStyledInput>`
  ${props => props.theme.mixins.label};
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
  cursor: pointer;

  ${props =>
    props.loading &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
  `}
`;

export const StyledLabelText = styled.label``;

export const StyledLabelRequired = styled.label`
  color: ${props => props.theme.colors.red};
`;

export const StyledInput = styled.div<IStyledInput>`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: ${props => props?.width || '100%'};
  position: relative;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.colors.darkGrey};
  background: ${props => props.theme.colors.white};
  ${props =>
    props.loading &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
    border: 2px solid ${props.theme.colors.black5};

    & input{
      background: transparent;
    }
  `}

  @media (max-width: 768px) {
    width: 100%;
  }

  & input,
  & textarea {
    width: 100%;
    border: 0;
    padding: 16px;
    margin: 0;
    -webkit-appearance: none;
    ${props => props.theme.mixins.body};
    color: ${({valid}) =>
      valid
        ? props => props.theme.colors.green
        : props => props.theme.colors.darkGrey};

    @media (max-width: 1024px) {
      /* padding: 16px */
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      ${props => props.theme.mixins.body}
      color: ${props => props.theme.colors.black20};
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      ${props => props.theme.mixins.body}
      color: ${props => props.theme.colors.black20};
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      ${props => props.theme.mixins.body}
      color: ${props => props.theme.colors.black20};
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      ${props => props.theme.mixins.body}
      color: ${props => props.theme.colors.black20};
    }
  }
`;

export const StyledCheckValidation = styled(Icon)`
  color: #44bf56;
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
  margin: 0 1.6rem 0 0;
  pointer-events: none;
  @media (max-width: 1024px) {
    margin: 0 1.3rem 0 0;
  }
`;

export const StyledCrossValidation = styled(Icon)`
  color: #ee6160;
  min-height: 16px;
  min-width: 16px;
  height: 16px;
  width: 16px;
  margin: 0 1.6rem 0 0;
  pointer-events: none;
  @media (max-width: 1024px) {
    margin: 0 1.3rem 0 0;
  }
`;
