import styled from 'styled-components';
import {StyledInput} from '../../ui/inputs/inputs.styles';

export const StyledSchoolDescription = styled.div`
  padding-top: 10px;
  span {
    font-weight: 900;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledLookup = styled.div`
  width: 100%;
  max-width: 50%;
  position: relative;

  & ${StyledInput} {
    position: relative;
    z-index: 1;
  }
`;
export const StyledLoading = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
`;

export const StyledSelected = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  border-radius: 4px;
  border: 2px solid ${props => props.theme.colors.green};
  background: ${props => props.theme.colors.white};
  padding: 16px;
  font-family: ${props => props.theme.fonts.circularBook};
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  @media (max-width: ${props => props.theme.breakPoints.tablet}) {
    font-size: 14px;
    line-height: 18px;
  }

  & > div {
    & svg,
    & span {
      color: ${props => props.theme.colors.green};
    }
    & svg {
      margin-right: 8px;
    }
  }
`;

export const StyledResults = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0 0 4px 4px;
  border: 2px solid ${props => props.theme.colors.darkGrey};
  width: 100%;
  max-height: 240px;
  overflow-y: scroll;
  margin-top: -4px;
  top: 100%;
  position: absolute;

  & > div {
    width: 100%;
    padding: 0 8px;
    cursor: pointer;
    font-family: ${props => props.theme.fonts.circularBook};
    font-size: 16px;
    line-height: 20px;
    display: flex;
    @media (max-width: ${props => props.theme.breakPoints.tablet}) {
      font-size: 14px;
      line-height: 18px;
    }

    &:nth-of-type(1) {
      padding-top: 8px;
    }
    &:nth-last-of-type(1) {
      padding-bottom: 8px;
    }

    & > span {
      width: 100%;
      padding: 8px;
      border-radius: 4px;

      &:hover {
        background: ${props => props.theme.colors.black5};
      }
    }
  }
`;
