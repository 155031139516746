import {useEffect, useState} from 'react';
import { IQuestionProps} from '../../QuestionForm/questionForm.types';
import { DatePickerCmp } from '../../ui/inputs/datePicker';

export const DateInput = ({
  stepRecord,
  questionMapping,
  handleChange,
}: IQuestionProps) => {
  const defaultValue = stepRecord?.[questionMapping || ''] || '';

  const [submitDate, setSubmitDate] = useState({
    value: defaultValue,
    valid: defaultValue !== '',
  });

  const defaultDateInputFormat = () => {
    if (questionMapping === 'dob') {
      return 'year';
    }

    return 'month';
  };

  useEffect(() => {
    handleChange(submitDate);
  }, [submitDate]);

  return (
    <DatePickerCmp
      label=" "
      format={defaultDateInputFormat()}
      defaultValue={defaultValue}
      onChange={(e: any) => setSubmitDate(e)}
    />
  );
};
