import { NavigateFunction } from 'react-router-dom';
import { IAppDispatch } from '../../context/AppContext/appContext.types';
import { IHideShowQuestion } from '../../data/types';
// import { GetAppDataDocument } from '../../graphql';
import { isSameValue, stringifyBoolean } from '../QuestionInputs/QuestionInputHelpers';
import {
  ICheckRefetchQueries,
  ICheckSkip,
  ICheckTermsAcceptance,
  IObjectKey,
  IReplaceVariablesInQuestions,
  IUpdateVisibleQuestions,
} from './steps.types';

export function sessionStoreValue(key: string) {

  const sessionStorageArr = ['program', 'campus', 'programStartTerm']

  return sessionStorageArr.includes(key)
}

export async function checkTermsAcceptance({
  dispatch,
  stepName,
  questionMapping,
  guid,
}: ICheckTermsAcceptance) {
  if (stepName === 'program' && questionMapping === 'program') {
    dispatch({
      type: 'uncomplete-step',
      payload: {
        guid,
        step: 'termsAndConditions',
      },
    });
  }
}

export function extractVariable(str: string) {
  const regex = /\{\{([^{}]+)\}\}/;
  const match = regex.exec(str);

  return match ? match[1] : null;
}

export function checkSkip({
  record,
  question,
  submittingVal,
  submittingName,
  step_name,
  session
}: ICheckSkip) {
  const { question_mapping, required, question_type } = question.content;

  const questionMapping = question_mapping || '';
  const defaultVal = record[questionMapping] || '';
  const notRequired = required === false;
  const noChange = isSameValue(defaultVal, submittingVal);
  const blankInput = submittingVal === '';

  // Edge case for program
  if (questionMapping === 'program') {
    if (!isSameValue(submittingName, submittingVal)) {
      return false;
    }
  }

  if (step_name === 'program' && sessionStoreValue(questionMapping)) {
    return !!session[questionMapping] && noChange
  }

  // If no change, skip
  if (noChange) return true;
  if (typeof defaultVal === 'boolean' || typeof submittingVal === 'boolean') {
    return stringifyBoolean(defaultVal) === stringifyBoolean(submittingVal);
  }
  // If not required and blank...
  if (notRequired && blankInput) {
    // if defaultVal was not blank but submitting value is, don't skip
    if (defaultVal !== submittingVal) {
      return false;
    }
    // not required and blank, can skip.
    return true;
  }

  if (question_type === 'picklist') {
    if (questionMapping === 'program') {
      return noChange;
    }
    // native language skip check
    if (questionMapping === 'primaryLanguage') {
      return defaultVal !== 'English' && submittingVal !== 'English';
    }
  }
  // doesn't meet edge case, do not skip
  return false;
}

export function checkHideShowCriteria({
  dependent_value,
  value,
  dependent_type,
  dependant_condition,
}: IHideShowQuestion) {
  const arr = dependent_value?.toLowerCase()?.split(', ');

  const inputVal = typeof value === 'string' ? value?.toLowerCase() : value
  const dependentValFound = value && arr?.includes(inputVal);
  const booleanDepFound = JSON.stringify(value) === dependent_value;

  const matchingCriteria =
    typeof value === 'boolean' ? booleanDepFound : dependentValFound;

  if (dependent_type === 'show') {
    if (dependant_condition === 'neq') {
      return !matchingCriteria;
    }
    if (dependant_condition === 'eq') {
      return matchingCriteria;
    }
  }

  if (dependent_type === 'hide' && matchingCriteria) {
    if (dependant_condition === 'eq') {
      return false;
    }

    if (dependant_condition === 'neq') {
      return true;
    }
  }

  return true;
}

export function updateVisibleQuestions({
  questions,
  newRecord,
}: IUpdateVisibleQuestions) {
  return questions.filter(question => {
    const {
      dependent_on,
      dependent_value,
      dependent_type,
      dependant_condition,
      second_dependent_condition,
      second_dependent_on,
      second_dependent_type,
      second_dependent_value,
    } = question.content;

    const firstDependentMatch = checkHideShowCriteria({
      dependent_value,
      value: newRecord[dependent_on || ''],
      dependent_type: dependent_type,
      dependant_condition,
    });

    const secondDependentMatch = checkHideShowCriteria({
      dependent_type: second_dependent_type,
      dependent_value: second_dependent_value,
      value: newRecord[second_dependent_on || ''],
      dependant_condition: second_dependent_condition,
    });

    if (!(firstDependentMatch && secondDependentMatch)) {
      return false;
    }

    return question;
  });
}

export function checkRefetchQueries({
  step_type,
  nextIndex,
  questions,
}: ICheckRefetchQueries) {
  const isMultiRecord = step_type === 'multi-record';
  const lastQuestion = nextIndex === questions.length;

  return isMultiRecord && lastQuestion
    ? ['GetApplicationMultiStep']
    : ['GetQuestionV2'];
}

export function replaceVariablesInQuestions({
  string,
  variableObj,
}: IReplaceVariablesInQuestions) {
  const sentenceVariable = extractVariable(string);

  if (sentenceVariable === 'school') {
    return string.replace(
      /\{\{([^{}]+)\}\}/g,
      JSON.parse(variableObj?.school).schoolName
    );
  }

  return string.replace(/\{\{([^{}]+)\}\}/g, (match, variable) => {
    return variableObj[variable] || match;
  });
}

export function leaveStep(
  navigate: NavigateFunction,
  questionQuery: IObjectKey
) {
  if (questionQuery.hasOwnProperty('editing')) {
    navigate({
      pathname: '/question',
      search: `step=${questionQuery?.step}`,
    });
  } else {
    navigate('/');
  }
}