import { AlertSa } from "hult-toolkit";
import { useState, useRef, useEffect } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { useSettingsContext } from "../../context/SettingsContext";
import { transformMarkdown } from "../../utils/markdownHelper";
import { variantMapping } from "../../utils/variantMapping";
import { AddressInput } from "../QuestionInputs/AddressInput";
import { DateInput } from "../QuestionInputs/DateInput";
import { EmailInput } from "../QuestionInputs/EmailInput";
import { LinkedInUrl } from "../QuestionInputs/LinkedInUrl";
import { LookUpInput } from "../QuestionInputs/LookUpInput";
import { MultiLookUpInput } from "../QuestionInputs/MultiLookupInput";
import { NumberInput } from "../QuestionInputs/NumberInput";
import { PayerManagement } from "../QuestionInputs/PayerManagement";
import { Picklist } from "../QuestionInputs/PickListInput";
import { RecommendationInput } from "../QuestionInputs/RecommendationInput";
import { SchoolLookUp } from "../QuestionInputs/SchoolLookUp";
import { TelephoneInput } from "../QuestionInputs/TelephoneInput";
import { TextInput } from "../QuestionInputs/TextInput";
import { LoadingInput } from "../QuestionInputs/TextInput/TextInput.helpers";
import { StyledQuestion } from "../Steps/steps.styles";
import { StyledQuestionWrapper, StyledText, StyledButtonContainer } from "./questionForm.styles";
import { QuestionFormButton } from "./questionFormButton";
import { IAnswerChange, IEventTarget, IQuestionForm } from "./questionForm.types";

export const QuestionForm = ({
  error,
  loading,
  question,
  handleChange,
  onClick,
  question_type,
  saving,
  stepRecord,
  description,
}: IQuestionForm) => {
  const {
    display_title,
    required,
    select_blok_options,
    lookup_datasource,
    select_ds_options,
    question_mapping,
    dependent_on,
  } = question?.content;

  const { errorMessage } = useSettingsContext();
  const [inputValue, setInputValue] = useState('');
  const [inputValid, setInputValid] = useState(false);
  const [inputName, setInputName] = useState('');
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);

  const questionMapping = question_mapping || '';
  const defaultVal = stepRecord?.[questionMapping] || '';

  useEffect(() => {
    if (!loading) {
      setInputValue(defaultVal);
      setInputValid(defaultVal !== '');
    }
  }, [question, loading]);

  useEffect(() => {
    handleChange({
      value: inputValue,
      valid: inputValid,
      name: inputName,
    });
  }, [inputValue, inputName]);

  if (error) {
    console.error(error);
    return (
      <StyledQuestion>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
            title={errorMessage.title || ""}
          />
        )}
      </StyledQuestion>
    );
  }

  const answerChange = ({ value, valid, name = '' }: IAnswerChange) => {
    setInputValue(value);
    setInputValid(valid);
    setInputName(name);
  };

  const textChange = ({ target }: IEventTarget) => {
    const val = target?.value;
    const inputValidity = target?.value !== '' && !/^\s*$/.test(val);

    setInputValue(val);
    setInputValid(inputValidity);
  };

  const questionProps = {
    stepRecord,
    questionMapping,
    handleChange: answerChange,
  };

  const questionTypeRender = () => {
    switch (question_type) {
      case 'address':
        return <AddressInput {...questionProps} />;
      case 'date':
        return <DateInput {...questionProps} />;
      case 'email':
        return <EmailInput {...questionProps} />
      case 'linkedin':
        return <LinkedInUrl {...questionProps} />;
      case 'lookup':
        return <LookUpInput {...questionProps} datasource={lookup_datasource} />;
      case 'number':
        return <NumberInput {...questionProps} />;
      case 'multi_lookup':
        return <MultiLookUpInput {...questionProps} datasource={lookup_datasource} />
      case 'payer':
        return <PayerManagement />;
      case 'picklist':
        return (
          <Picklist
            select_ds_options={select_ds_options}
            select_blok_options={select_blok_options}
            dependentOn={dependent_on}
            {...questionProps}
          />
        );
      case 'recommendation':
        return <RecommendationInput {...questionProps} />;
      case 'school_lookup':
        return (
          <SchoolLookUp {...questionProps} datasource={lookup_datasource} />
        );
      case 'telephone':
        return <TelephoneInput {...questionProps} />;
      case 'text':
        return (
          <TextInput
            type={question_type}
            value={inputValue}
            onChange={textChange}
            valid={inputValid}
          />
        );
      default:
        console.log(`INVALID INPUT: ${question_type}`);
        return <></>;
    }
  };

  const invalidInput = required && inputValid === false;

  if (loading) {
    return <LoadingInput />;
  }

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.innerHTML = transformMarkdown(description).replace(
        /<\/?span[^>]*>/g,
        ''
      );
    }
  }, [description]);

  return (
    <StyledQuestionWrapper>
      <StyledText>
        <h2>{display_title}</h2>
        <p ref={descriptionRef}></p>
      </StyledText>
      {questionTypeRender()}
      {question_type !== 'payer' && (
        <StyledButtonContainer>
          <QuestionFormButton
            saving={saving}
            onClick={onClick}
            disabled={invalidInput}
          />
        </StyledButtonContainer>
      )}
    </StyledQuestionWrapper>
  );
};
