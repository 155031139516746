import { useEffect, useState } from 'react';
import { TextInput } from '../TextInput';
import { LookUpInput } from '../LookUpInput';
import { IAddressUI } from './Address.types';
import { checkFieldValidity, checkIfOnlySpaces, checkValidAddressState } from '../QuestionInputHelpers';
import { IEvent } from '../../QuestionForm/questionForm.types';
import { StyledAddressWrapper } from './address.styles';

export const AddressUI = ({ defaultValue, handleChange }: IAddressUI) => {
  const [addressObj, setAddressObj] = useState(defaultValue);
  const [validCountry, setValidCountry] = useState(false);

  const validState = checkValidAddressState(addressObj?.state) && checkFieldValidity(addressObj?.state)

  useEffect(() => {
    if (addressObj) {
      const mandatoryFieldsAndValues = Object.entries(addressObj)
        .filter(entry => entry[0] !== 'address2')

      const checkValidity = mandatoryFieldsAndValues.every(ent => {
        if (ent[0] === 'state') {
          return validState
        }

        return !checkIfOnlySpaces(ent[1]);
      });

      handleChange({
        value: addressObj,
        valid: checkValidity && validCountry,
      });
    }
  }, [addressObj]);

  return (
    <StyledAddressWrapper>
      <LookUpInput
        questionMapping=""
        label="Country/Region"
        defaultVal={addressObj?.country}
        datasource="country-lookup"
        handleChange={(e: IEvent) => {
          setValidCountry(e.valid);
          setAddressObj({
            ...addressObj,
            country: e.value,
            countryName: e.name,
          });
        }}
      />
      <TextInput
        label="Address Line 1"
        value={addressObj?.address1 || ''}
        valid={checkFieldValidity(addressObj?.address1)}
        id="address1"
        onChange={e => {
          setAddressObj({
            ...addressObj,
            address1: e.target.value,
          });
        }}
      />
      <TextInput
        label="Address Line 2 (optional)"
        value={addressObj?.address2 || ''}
        valid={
          addressObj?.address2 && !checkIfOnlySpaces(addressObj?.address2)
            ? true
            : undefined
        }
        id="address2"
        onChange={e => {
          setAddressObj({
            ...addressObj,
            address2: e.target.value,
          });
        }}
      />
      <TextInput
        label="City"
        value={addressObj?.city || ''}
        valid={checkFieldValidity(addressObj?.city)}
        id="city"
        onChange={e =>
          setAddressObj({
            ...addressObj,
            city: e.target.value,
          })
        }
      />
      <TextInput
        label="Post/Zip Code"
        value={addressObj?.postalCode || ''}
        valid={checkFieldValidity(addressObj?.postalCode)}
        id="postalCode"
        onChange={e =>
          setAddressObj({
            ...addressObj,
            postalCode: e.target.value,
          })
        }
      />
      <TextInput
        label="State/County/Province"
        value={addressObj?.state || ''}
        valid={validState}
        id="state"
        onChange={e =>
          setAddressObj({
            ...addressObj,
            state: e.target.value,
          })
        }
      />
    </StyledAddressWrapper>
  );
};
