import styled from 'styled-components';

export const StyledAccount = styled.div``;

export const StyledTitle = styled.h1``;

export const StyledInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  background: white;
  padding: 24px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
`;

export const StyledInformation = styled.div``;

export const StyledData = styled.p`
  margin: 0;
  color: #2b2b2b;
`;

export const StyledSubTitle = styled.p`
  margin: 0;
  color: #2b2b2b;
  font-family: ${props => props.theme.fonts.circularMedium};
`;
