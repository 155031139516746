import { useEffect, useState } from 'react';
import {
  StyledDropDown,
  StyledLookup,
  StyledOption,
} from '../../ui/inputs/lookup/lookup.styles';
import { ILookUpUI } from './LookUp.types';
import { LookUpValidationIcon } from '../TextInput/TextInput.helpers';
import { IOptions } from '../../ui/inputs/inputs.types';
import { StyledInput, StyledInputWrapper, StyledLabel } from '../../ui/inputs/inputs.styles';

export const LookUpUI = ({
  defaultObj,
  options,
  optionChange,
  label,
}: ILookUpUI) => {
  const [inputVal, setInputVal] = useState(defaultObj?.label || '');
  const [submitObj, setSubmitObj] = useState(defaultObj);
  const [optionSelected, setOptionSelected] = useState(
    submitObj?.value ? true : false
  );
  const [filteredOptions, setFilteredOptions] = useState<
    IOptions[] | undefined
  >(options);

  const emptyVal = inputVal === '';
  const noMatches = filteredOptions && filteredOptions?.length !== 0;

  useEffect(() => {
    setInputVal(defaultObj?.label || '');
    setSubmitObj(defaultObj);
  }, [defaultObj]);

  useEffect(() => {
    const targetVal = inputVal.toLowerCase();

    const filteredRes = options?.filter(({ label }) => {
      return label?.toLowerCase().includes(targetVal);
    });

    setFilteredOptions(filteredRes);
  }, [inputVal]);

  useEffect(() => {
    const res = {
      name: submitObj?.label || '',
      value: submitObj?.value || '',
      valid: emptyVal ? false : optionSelected,
    };

    optionChange(res);
  }, [optionSelected, submitObj]);

  const showList = !emptyVal && noMatches && !optionSelected;

  const validationIconLogic = () => {
    if (emptyVal) return undefined;
    if (filteredOptions?.length === 0 && !optionSelected) return false;
    if (optionSelected) return true;
  };

  return (
    <StyledInputWrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledLookup>
        <StyledInput>
          <input
            type="text"
            value={inputVal}
            placeholder={''}
            disabled={false}
            onChange={e => {
              setInputVal(e?.target?.value);
              setOptionSelected(false);
              setSubmitObj(undefined);
            }}
          />
          {LookUpValidationIcon(validationIconLogic())}
        </StyledInput>
        {showList && (
          <StyledDropDown>
            {filteredOptions?.map((option: any, idx: number) => (
              <StyledOption
                key={`lookup-option-${idx}`}
                onClick={() => {
                  setFilteredOptions([]);
                  setOptionSelected(true);
                  setInputVal(option.label);
                  setSubmitObj(option);
                }}
              >
                {option?.label}
              </StyledOption>
            ))}
          </StyledDropDown>
        )}
      </StyledLookup>
    </StyledInputWrapper>
  );
};
