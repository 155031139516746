import React from 'react';
import {dateFormatter} from './multiRecordSteps.helpers';
import {isValidJSON} from '../QuestionInputs/QuestionInputHelpers';
import {CardStepSa} from 'hult-toolkit';

export interface IMultiRecordEducationCard {
  step?: string;
  title?: string;
  startDate?: Date;
  school?: string;
  endDate?: Date;
  onClickDelete?: any;
  onClickEdit?: any;
  loading?: boolean;
  complete?: boolean;
}

export const MultiRecordEducationCard: React.FC<IMultiRecordEducationCard> = ({
  title,
  startDate,
  endDate,
  onClickDelete,
  onClickEdit,
  school,
  loading,
  complete,
}) => {
  const parsedSchool = school
    ? isValidJSON(school)
      ? JSON.parse(school)
      : ''
    : '';

  return (
    <CardStepSa
      desc={
        (startDate && !isNaN(startDate.valueOf())
          ? dateFormatter(startDate)
          : 'Unknown') +
        ' - ' +
        (endDate && !isNaN(endDate.valueOf())
          ? dateFormatter(endDate)
          : 'Unknown')
      }
      multistep
      multistep_title={title}
      label={'Incomplete'}
      onEdit={() => onClickEdit()}
      onDelete={() => onClickDelete()}
      state={loading ? 'loading' : complete ? 'complete' : 'default'}
      title={parsedSchool.schoolName}
    />
  );
};
