import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IStep } from '../../data/types';
import { useAppContext, useAppDispatch } from '../../context/AppContext';
import { AdyenPayment } from '../PaymentTypes/AdyenPayment';
import { FlywireAPayment } from '../PaymentTypes/FlywirePayment';
import { useCompleteApplicationStepMutation } from '../../graphql';
import { StyledPayment } from './steps.styles';

export const StepPayment: React.FC<IStep> = ({
  display_name,
  step_name,
  payment_type,
  isPreview,
}) => {
  const { selectedApplication } = useAppContext();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation()

  async function handleCompletePayment() {
    switch (step_name) {
      case 'applicationFee':
        dispatch({
          type: 'ga-event',
          payload: {
            event: 'stdapp_appFeePaid',
            content: '(3.0) - Application Fee Paid',
          },
        });
        break;
      case 'confirmationDeposit':
        dispatch({
          type: 'ga-event',
          payload: {
            event: 'stdapp_confirmationPaid',
            content: '(3.0) - Confirmation Fee Paid',
          },
        });
        break;
      case 'tuitionFee':
        dispatch({
          type: 'ga-event',
          payload: {
            event: 'stdapp_tuitionPaid',
            content: '(3.0) - Tuition Fee Paid',
          },
        });
        break;
    }

    if (selectedApplication) {
      await completeApplicationStepMutation({
        variables: {
          guid: selectedApplication,
          step: step_name || ''
        },
        onCompleted() {
          dispatch({
            type: 'complete-step',
            payload: {
              guid: selectedApplication,
              step: step_name,
            },
          });
        },
        onError(err) {
          console.log("completeApplicationStepMutation", err)
        }
      })

      setTimeout(() => {
        navigate({
          pathname: `/`,
        });
      }, 5000);
    }
  }

  switch (payment_type) {
    case 'adyen':
      return (
        <StyledPayment>
          <h1>{display_name}</h1>
          <AdyenPayment
            step_name={step_name || ''}
            handleCompletePayment={handleCompletePayment}
            isPreview={isPreview}
            display_name={display_name || ''}
          />
        </StyledPayment>
      );
    case 'flywire':
    case 'tuition_fee':
      return (
        <StyledPayment>
          <h1>{display_name}</h1>
          <FlywireAPayment
            display_name={display_name || ''}
            step_name={step_name || ''}
          />
        </StyledPayment>
      );
    default:
      return <></>;
  }
};
