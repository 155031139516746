import {useEffect, useState, FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {render} from 'storyblok-rich-text-react-renderer';
import {ISbStoryData} from '@storyblok/react';
import {IMultiStep, ISbQuestion, IStep, IStudentType} from '../../data/types';
import {StyledQuestion, StyledQuestionWrapper} from './steps.styles';
import {useAppContext, useAppDispatch} from '../../context/AppContext';
import {useSettingsContext} from '../../context/SettingsContext';
import {defaultQuestionQuery, generalStepUrl} from '../../utils';
import {
  GetQuestionV2Document,
  useCompleteApplicationStepMutation,
  useGetQuestionV2Query,
  useUpdateApplicationStepMutation,
} from '../../graphql';
import {
  checkRefetchQueries,
  checkSkip,
  checkTermsAcceptance,
  leaveStep,
  replaceVariablesInQuestions,
  updateVisibleQuestions,
} from './steps.helpers';
import {AlertSa} from 'hult-toolkit';
import {variantMapping} from '../../utils/variantMapping';
import {QuestionForm} from '../QuestionForm';
import {LoadingInput} from '../QuestionInputs/TextInput/TextInput.helpers';
import {QuestionBackButton} from '../QuestionInputs/QuestionBackButton';

export const MultiStepStandard: FC<IMultiStep> = ({
  step_category_name = '',
  step_name,
  step_type,
  questions = [],
  isPreview,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {program, session, selectedApplication} = useAppContext();
  const {errorMessage} = useSettingsContext();
  const [updateApplicationStepMutation] = useUpdateApplicationStepMutation();
  const [completeApplicationStepMutation] =
    useCompleteApplicationStepMutation();

  const [submitting, setSubmitting] = useState(false);
  const [stepRecord, setStepRecord] = useState<IStudentType>({});
  const [visibleQuestions, setVisibleQuestions] = useState<
    ISbStoryData<ISbQuestion>[]
  >([]);
  const [submitObj, setSubmitObj] = useState({
    value: '',
    name: '',
    valid: false,
  });
  const [questionQuery, setQuestionQuery] = useState(defaultQuestionQuery());

  useEffect(() => {
    navigate({
      pathname: '/question',
      search: generalStepUrl(questionQuery),
    });
  }, [questionQuery, submitting]);

  const {loading, error} = useGetQuestionV2Query({
    variables: {
      guid: selectedApplication || '',
      step: step_name || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: isPreview || !selectedApplication || !step_name,
    onCompleted({getApplicationStep}) {
      const savedRecord = getApplicationStep || {};

      const displayedQuestions = updateVisibleQuestions({
        questions,
        newRecord: savedRecord,
      });

      setStepRecord(savedRecord);
      setVisibleQuestions(displayedQuestions);
    },
    onError(err) {
      console.log('useGetQuestionV2Query', err.message);
    },
  });

  async function skipSubmit(newQuestionQuery: any) {
    const nextQIndex = Number(newQuestionQuery.q);

    if (nextQIndex === visibleQuestions.length) {
      await handleFinalQuestion();
    } else {
      setQuestionQuery(newQuestionQuery);
      setSubmitting(false);
      navigate({
        pathname: '/question',
        search: generalStepUrl(newQuestionQuery),
      });
    }
  }

  function handleBack() {
    const prevIndex = Number(questionQuery.q) - 1;
    const newQuestionQuery = {
      ...questionQuery,
      q: `${prevIndex}`,
    };

    setSubmitting(false);

    if (prevIndex < 0) {
      leaveStep(navigate, questionQuery);
    }

    setQuestionQuery(newQuestionQuery);
  }

  async function handleFinalQuestion() {
    await completeApplicationStepMutation({
      variables: {
        guid: selectedApplication || '',
        step: step_name || '',
      },
      onCompleted() {
        dispatch({
          type: 'complete-step',
          payload: {
            guid: selectedApplication || '',
            step: step_category_name,
          },
        });

        dispatch({
          type: 'ga-event',
          payload: {
            event: 'stdapp_sectionComplete',
            content: `(3.0) - ${step_category_name} - ${program}`,
          },
        });

        navigate({
          pathname: `/question`,
          search: `step=${questionQuery.step}`,
        });
      },
      onError(err) {
        console.log('completeApplicationStepMutation', err.message);
      },
    });
  }

  async function handleSubmit(
    question: ISbStoryData<ISbQuestion>,
    submittingVal: any
  ) {
    try {
      let questionMapping = question.content.question_mapping || '';

      if (questionMapping.startsWith('recommendation')) {
        questionMapping = 'recommendation';
      }

      setSubmitting(true);

      const skipConditions = checkSkip({
        step_name: step_name || '',
        record: stepRecord,
        question,
        submittingVal,
        submittingName: submitObj.name,
        session,
      });

      const newQuestionQuery = {
        ...questionQuery,
        q: `${Number(questionQuery.q) + 1}`,
      };

      const newData = {
        ...stepRecord,
        [questionMapping]: submittingVal,
      };

      const nextIndex = Number(newQuestionQuery.q);

      const newVisibleQuestions = updateVisibleQuestions({
        questions,
        newRecord: newData,
      });

      const refetchQueries = checkRefetchQueries({
        step_type,
        nextIndex,
        questions: newVisibleQuestions,
      });

      if (selectedApplication && step_name) {
        // check edge cases that do not require a submit
        if (skipConditions) {
          console.log('skipping question submit...');
          await skipSubmit(newQuestionQuery);
        } else {
          await checkTermsAcceptance({
            stepName: step_name,
            questionMapping,
            dispatch,
            guid: selectedApplication || '',
          });

          await updateApplicationStepMutation({
            variables: {
              guid: selectedApplication || '',
              step: step_name,
              [questionMapping]: submitObj?.value,
            },
            refetchQueries: refetchQueries,
            onCompleted() {
              dispatch({
                type: 'update-student-type-cache',
                payload: {
                  guid: selectedApplication || '',
                  step: step_name,
                  newData,
                  query: GetQuestionV2Document,
                },
              });

              if (nextIndex === newVisibleQuestions.length) {
                handleFinalQuestion();
              }
            },
            onError(error) {
              console.error('updateApplicationStepMutation error', error);
            },
          });

          if (nextIndex !== newVisibleQuestions.length) {
            setStepRecord(newData);
            setVisibleQuestions(newVisibleQuestions);
            setQuestionQuery(newQuestionQuery);
            setSubmitting(false);
          }
        }
      }
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  }

  function handleChange({value, valid, name}: any) {
    setSubmitObj({
      value,
      valid,
      name,
    });
  }

  if (error) {
    console.error(error);

    return (
      <StyledQuestion>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
            title={errorMessage.title || ''}
          />
        )}
      </StyledQuestion>
    );
  }

  if (!visibleQuestions.length || loading) {
    return <LoadingInput />;
  }

  const displayQuestions = visibleQuestions.map((q, i) => {
    if (i === Number(questionQuery?.q)) {
      const variableObj = {
        ...stepRecord,
        ...session,
      };

      const displayedDesc = replaceVariablesInQuestions({
        string: q.content.description || '',
        variableObj,
      });

      return (
        <StyledQuestionWrapper key={i}>
          <QuestionBackButton onClick={handleBack} />
          <QuestionForm
            description={displayedDesc}
            error={error}
            loading={loading}
            question={q}
            handleChange={handleChange}
            onClick={() => handleSubmit(q, submitObj.value)}
            question_type={q.content.question_type}
            saving={submitting}
            stepRecord={stepRecord}
          />
        </StyledQuestionWrapper>
      );
    }
  });

  return <StyledQuestion>{displayQuestions}</StyledQuestion>;
};
