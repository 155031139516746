import { StyledQuestionContainer } from '../../QuestionForm/questionForm.styles';
import { ITextInput } from './TextInput.types';
import { 
  StyledCheckValidation, 
  StyledCrossValidation, 
  StyledInputWrapper, 
  StyledLabel, 
  StyledLabelText, 
  StyledLabelRequired, 
  StyledInput 
} from '../../ui/inputs/inputs.styles';

export const TextInput = (inputProps: ITextInput) => {
  const { onChange, id, value, type, valid, label, errorMessage } = inputProps;

  const validationIcon = () => {
    if (valid) {
      return <StyledCheckValidation icon="check-circle" />;
    }

    if (valid === false && value !== '') {
      return <StyledCrossValidation icon="times-circle" />;
    }
  };

  return (
    <StyledQuestionContainer question_type={type}>
      <StyledInputWrapper hidden={false}>
        <StyledLabel htmlFor={id}>
          <StyledLabelText>{label}</StyledLabelText>
          <StyledLabelRequired>
            <span>{errorMessage}</span>
          </StyledLabelRequired>
        </StyledLabel>
        <StyledInput>
          <input type="text" value={value} id={id} onChange={onChange} />
          {validationIcon()}
        </StyledInput>
      </StyledInputWrapper>
    </StyledQuestionContainer>
  );
};
