import React, { useEffect } from 'react';
import { SbBlokData } from '@storyblok/react';
import { useAppContext, useAppDispatch } from '../../context/AppContext';
import { IDictionary, IStep } from '../../data/types';
import { useCompleteApplicationStepMutation } from '../../graphql';
import {
  StyledSubmitApplication,
  StyledSubmitApplicationContainer,
} from './submitApplication.styles';
import { StatusMessageSa } from 'hult-toolkit';

interface ISubmitApplicationBlokData extends SbBlokData {
  cta_text: string;
  description: string;
  title: string;
  disabled_description: string;
  disabled_title: string;
}

export interface ISubmitApplication {
  blok: ISubmitApplicationBlokData;
  steps: IStep[];
}

export const SubmitApplication: React.FC<ISubmitApplication> = ({
  blok: { cta_text, title, disabled_title },
  steps,
}) => {
  const { appStage, completedStepsDetails, selectedApplication } = useAppContext();
  const dispatch = useAppDispatch();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation()


  let completed = false;

  useEffect(() => {
    document.body.classList.add('banner-active');
    document.querySelector('footer')?.classList.add('banner-active');

    return () => {
      document.body.classList.remove('banner-active');
      document.querySelector('footer')?.classList.remove('banner-active');
    };
  }, []);

  // Check if we have uncompleted steps, if not, set complete to true
  if (
    steps.filter(
      ({ content: { step_name } }) =>
        completedStepsDetails &&
        !(completedStepsDetails as IDictionary<any>)[`${step_name}Completed`]
    ).length === 0
  ) {
    completed = true;
  }

  async function handleApplicationSubmit() {
    // If program step completed update cache for appStage
    if (completed && selectedApplication) {

      await completeApplicationStepMutation({
        variables: {
          guid: selectedApplication,
          step: 'submitApplication'
        },
        onCompleted(data) {
          dispatch({
            type: 'complete-step',
            payload: {
              guid: selectedApplication,
              step: 'submitApplication',
            },
          });
        },
        onError(err) {
          console.log("completeApplicationStepMutation error", err);
        }
      })

      dispatch({
        type: 'update-state',
        payload: {
          appStage: 'Partial Application',
        },
      });

      dispatch({
        type: 'ga-event',
        payload: {
          event: 'stdapp_stageChange',
          content: `(3.0) - Partial Application`,
        },
      });

      dispatch({
        type: 'ga-event',
        payload: {
          event: 'stdapp_submitApplication',
        },
      });
    }
  }

  const readyForPartial = completed &&
    selectedApplication &&
    appStage
    !completedStepsDetails?.submitApplicationCompleted

  return (
    <StyledSubmitApplication
      onClick={() => {
        try {
          if (readyForPartial) {
            handleApplicationSubmit();
          }
        } catch (err) {
          console.log(err);
        }
      }}
    >
      <StyledSubmitApplicationContainer>
        <StatusMessageSa
          label={completed ? title : disabled_title}
          icon_color={completed ? 'black' : 'green'}
          button={[
            {
              label: cta_text,
              variant: completed ? 'primary' : 'disabled',
              icon: { name: 'arrow-right' },
            },
          ]}
          icon={completed ? 'circle-arrow-up' : 'circle-info'}
        />
      </StyledSubmitApplicationContainer>
    </StyledSubmitApplication>
  );
};
