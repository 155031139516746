import React, {FC, useEffect, useRef, useState} from 'react';
import {
  StyledInputWrapper,
  StyledLabel,
  StyledLabelRequired,
  StyledLabelText,
  StyledCheckValidation,
  StyledCrossValidation,
} from '../inputs.styles';
import {
  StyledDateInput,
  StyledDatePicker,
  StyledDatePickerDesktop,
  StyledDatesInputWrapper,
} from './datePicker.styles';

import {isValidDate} from './datePicker.helpers';

export interface IDatePicker {
  label?: string;
  name?: string;
  format?: 'month' | 'year';
  defaultValue?: Date | string | undefined;
  onChange?: any;
  _uid?: string;
  maxYear?: number;
  minYear?: number;
}

interface IDateFormChangeEvent extends React.FormEvent<HTMLFormElement> {
  target: any & {
    name: string;
    value: string;
  };
}

const addZero = (num: number) => (num < 10 ? `0${num}` : num);

const getDefaultDates = (defaultValue?: Date | string | undefined) => {
  const defaultString = defaultValue && new Date(defaultValue);

  const defaultDates = {
    day: defaultString ? `${addZero(defaultString.getDate())}` : '',
    month: defaultString ? `${addZero(defaultString.getMonth() + 1)}` : '',
    year: defaultString ? `${defaultString.getFullYear()}` : '',
  };

  return defaultDates;
};

export const DatePickerCmp: FC<IDatePicker> = ({
  label,
  format = 'month',
  defaultValue,
  onChange,
  maxYear = 2100,
  minYear = 1900,
}) => {

  const defaultDates = getDefaultDates(defaultValue);

  const [inputDay, setInputDay] = useState<string>('');
  const [inputMonth, setInputMonth] = useState<string>('');
  const [inputYear, setInputYear] = useState('');
  const [validDate, setValidDate] = useState<boolean | undefined>(false);

  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const incompleteDate = [inputMonth, inputDay, inputYear].some(dateType =>
    dateType === inputYear && dateType.length < 4
      ? true
      : dateType.toString() === 'NaN' || dateType === '' || dateType === '0'
  );

  const formatValidity = incompleteDate
    ? undefined
    : isValidDate(inputMonth, inputDay, inputYear);

  useEffect(() => {
    setInputDay(format === 'month' ? '01' : defaultDates.day);
    setInputMonth(defaultDates.month);
    setInputYear(`${defaultDates.year}`);
    setValidDate(formatValidity);
  }, [defaultValue]);

  useEffect(() => {
    setValidDate(formatValidity);
  }, [inputDay, inputMonth, inputYear, format]);

  const refocusInput = (type: 'month' | 'day') => {
    if (type === 'month') {
      format === 'year' ? dayRef?.current?.focus() : yearRef?.current?.focus();
    } else if (type === 'day') {
      yearRef?.current?.focus();
    }
  };

  const handleDateFormChange = ({target}: IDateFormChangeEvent) => {
    const {name, value} = target;

    let currentMonth = monthRef?.current?.value as string;
    let currentDay = dayRef?.current?.value as string;
    let currentYear = yearRef?.current?.value as string;

    const saveNumber = value;
    const lowerThanTen = parseInt(saveNumber) < 10;
    const strInputLength = saveNumber.toString().length;

    const completedDigits = strInputLength === 2;
    const completeMonthInput = parseInt(saveNumber) > 1 && lowerThanTen;
    const completeDayInput = parseInt(saveNumber) > 3 && lowerThanTen;

    switch (name) {
      case 'month':
        if (strInputLength > 2) {
          target.value = inputMonth;
          refocusInput('month');
        } else if (completedDigits) {
          setInputMonth(saveNumber);
          refocusInput('month');
        } else if (completeMonthInput) {
          const res = saveNumber[0] !== '0' ? `0${saveNumber}` : saveNumber;
          setInputMonth(res);
          target.value = res;
          refocusInput('month');
        } else {
          setInputMonth(saveNumber);
        }
        break;
      case 'day':
        if (strInputLength > 2) {
          target.value = inputDay;
          refocusInput('day');
        } else if (completedDigits) {
          setInputDay(saveNumber);
          refocusInput('day');
        } else if (completeDayInput) {
          const res = saveNumber[0] !== '0' ? `0${saveNumber}` : saveNumber;
          setInputDay(res);
          target.value = res;
          refocusInput('day');
        } else {
          setInputDay(saveNumber);
        }
        break;
      case 'year':
        if (saveNumber.length > 4) {
          target.value = inputYear;
        } else {
          setInputYear(saveNumber);
        }
        break;
    }

    const dateArr =
      format === 'year'
        ? [currentMonth, currentDay, currentYear]
        : [currentMonth, currentYear];

    const incompleteDate = dateArr.some(dateType =>
      dateType && dateType === currentYear && dateType.length < 4
        ? true
        : dateType.toString() === 'NaN' || dateType === '' || dateType === '0'
    );

    const monthLogic = currentMonth.length > 2 ? inputMonth : currentMonth;
    const dayLogic =
      currentDay && currentDay.length > 2 ? inputDay : currentDay;
    const yearLogic =
      currentYear && currentYear.length > 4 ? inputYear : currentYear;

    const dayFormat = format === 'year' ? dayLogic : '01';

    const currentValidity = isValidDate(monthLogic, dayFormat, yearLogic);
    const inputDate = `${yearLogic}-${monthLogic}-${dayFormat}`;
    const formatValidity = incompleteDate ? undefined : currentValidity;

    const valueLogic = formatValidity
      ? new Date(inputDate).toISOString()
      : inputDate;

    onChange({
      value: incompleteDate ? '' : valueLogic,
      valid: formatValidity === true,
    });
  };

  return (
    <StyledInputWrapper>
      <StyledLabel>
        <StyledLabelText>{label && label}</StyledLabelText>
        <StyledLabelRequired>
          {validDate === false && <span>Please enter a valid date</span>}
        </StyledLabelRequired>
      </StyledLabel>
      <StyledDatePickerDesktop>
        <form
          onChange={e => {
            handleDateFormChange(e);
          }}
        >
          <StyledDatePicker>
            <StyledDatesInputWrapper>
              <StyledDateInput
                ref={monthRef}
                id="month"
                name="month"
                placeholder="MM"
                max={12}
                type="number"
                validity={validDate}
                value={inputMonth}
                onBlur={({target}: IDateFormChangeEvent) => {
                  if (target.value === '1') {
                    if (target.value.length !== 2) {
                      target.value = '01';
                      setInputMonth('01');
                    }
                  } else if (target.value.length === 1) {
                    target.value = `0${target.value}`;
                  }
                }}
              />
              {format === 'year' && (
                <>
                  <span>/</span>
                  <StyledDateInput
                    ref={dayRef}
                    id="day"
                    name="day"
                    placeholder="DD"
                    max={32}
                    type="number"
                    validity={validDate}
                    value={inputDay}
                    onBlur={({target}: IDateFormChangeEvent) => {
                      const intVal = Number(target.value);
                      if (intVal < 4) {
                        if (target.value.length === 1 && target.value !== '0') {
                          target.value = `0${target.value}`;
                        }
                      } else {
                        if (
                          target.value.length !== 2 &&
                          target.value.length < 3
                        ) {
                          target.value = `0${target.value}`;
                        }
                      }
                    }}
                  />
                </>
              )}
              <span>/</span>
              <StyledDateInput
                ref={yearRef}
                id="year"
                validity={validDate}
                type="number"
                name="year"
                placeholder="YYYY"
                maxLength={4}
                max={maxYear}
                min={minYear}
                value={inputYear}
              />
            </StyledDatesInputWrapper>
            {validDate && <StyledCheckValidation icon="check-circle" />}
            {validDate === false && (
              <StyledCrossValidation icon="times-circle" />
            )}
          </StyledDatePicker>
        </form>
      </StyledDatePickerDesktop>
    </StyledInputWrapper>
  );
};
