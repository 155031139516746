import React from 'react';
import {ICard} from './card.types';
import {StyledCard} from './card.styles';

export const Card: React.FC<ICard> = ({
  children,
  border_color,
  border_position_left = false,
  hover = false,
  onClick,
  padding = true,
  loading,
  title,
}) => {
  return (
    <StyledCard
      padding={padding}
      loading={loading}
      border_color={border_color}
      border_position_left={border_position_left}
      hover={hover}
      onClick={typeof onClick !== 'undefined' ? () => onClick() : undefined}
      data-testid="card"
      data-name={title}
    >
      {children}
    </StyledCard>
  );
};
