export const moveCursorToEnd = (id: string) => {
  const el = document.getElementById(id) as HTMLInputElement;

  if (typeof el.selectionStart === 'number') {
    el.focus();
    el.selectionStart = el.selectionEnd = el.value.length;
    //@ts-ignore
  } else if (typeof el.createTextRange !== 'undefined') {
    //@ts-ignore
    var range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
};

export function htmlId (_uid?: string) {
  return Math.random().toString(36).substring(2, 9) + (_uid ? `-${_uid}` : ``);
}