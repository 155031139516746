import { AlertSa } from "hult-toolkit";
import { useState, useEffect } from "react";
import { useSettingsContext } from "../../../context/SettingsContext";
import { IOptions } from "../../../data/types";
import { getDatasourceEntries } from "../../../services/storyblok";
import { variantMapping } from "../../../utils/variantMapping";
import { LoadingInput } from "../TextInput/TextInput.helpers";
import { ILookUpInput } from "./LookUp.types";
import { LookUpUI } from "./LookUpUI";

export const LookUpInput = ({
  datasource,
  handleChange,
  label,
  stepRecord,
  questionMapping,
  defaultVal,
}: ILookUpInput) => {
  const inputVal = defaultVal || stepRecord?.[questionMapping || ''] || '';

  const [dsOptions, setDsOptions] = useState<IOptions[] | undefined>([]);
  const [selectedOption, setSelectedOption] = useState({
    value: '',
    valid: false,
  });
  const [selectedObject, setSelectedObject] = useState<any>();
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const { errorMessage } = useSettingsContext();

  useEffect(() => {
    if (datasource) {
      setOptionsLoaded(false);

      getDatasourceEntries(datasource)
        .then(data => {
          const res = data;

          const resDefault = res.find(
            ({ value, label }: IOptions) =>
              value === inputVal || label === inputVal
          );

          setDsOptions(res);
          setOptionsLoaded(true);
          setSelectedObject(resDefault);
        })
        .catch(error => {
          console.error(error);
          const storyblokError = errorMessage?.desc?.content[0].content[0].text;
          return (
            <>
              {errorMessage && (
                <AlertSa
                  desc={storyblokError}
                  icon={variantMapping[errorMessage.variant].icon}
                  icon_color={variantMapping[errorMessage?.variant].icon_color}
                  title={errorMessage?.title || ''}
                />
              )}
            </>
          );
        });
    }
  }, [datasource, questionMapping]);

  useEffect(() => {
    if (optionsLoaded) {
      handleChange(selectedOption);
    }
  }, [selectedOption]);

  if (!optionsLoaded) {
    return <LoadingInput />;
  }

  return (
    <LookUpUI
      label={label}
      options={dsOptions}
      defaultObj={selectedObject}
      optionChange={(e: any) => {
        if (optionsLoaded) {
          setSelectedOption(e);
        }
      }}
    />
  );
};
