import React from 'react';
import { CardStepSa } from 'hult-toolkit';
import { useAppContext } from '../../context/AppContext';
import { ISbStoryData } from '@storyblok/react';
import { useGetAcceptanceLetterV2Query } from '../../graphql';

export interface ICardResource {
  blok: ISbStoryData & {
    label: string;
    title: string;
    description: string;
    link: any;
    doc: string;
  };
}

export const CardResource: React.FC<ICardResource> = ({ blok }) => {
  const { acceptanceLetter, selectedApplication } = useAppContext();
  const acceptanceLetterId = acceptanceLetter?.acceptanceLetter;
  const acceptanceLetterConditionsMet = acceptanceLetter?.notifiedDate;


  const { loading, data } = useGetAcceptanceLetterV2Query({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 360000,
    skip: !acceptanceLetter?.notifiedDate,
  });

  let resourceIcon = 'arrow-up-right-from-square';

  switch (blok.label) {
    case 'App':
      resourceIcon = 'mobile';
      break;
    case 'Doc':
      resourceIcon = 'memo';
      break;
    case 'Link':
      resourceIcon = 'arrow-up-right-from-square';
      break;
    case 'Site':
      resourceIcon = 'desktop';
      break;
    default:
      break;
  }

  if (loading) {
    return <></>
  }

  let acceptanceLetterUrl = '';
  if (acceptanceLetterConditionsMet && acceptanceLetterId) {
    acceptanceLetterUrl = `${process.env.REACT_APP_ACCEPTANCE_URL}=${acceptanceLetterId}`;
  } else if (acceptanceLetterConditionsMet && data?.getApplicationAcceptanceLetter?.acceptanceLetterUrl) {
    acceptanceLetterUrl = data.getApplicationAcceptanceLetter.acceptanceLetterUrl;
  }

  return (
    <CardStepSa
      desc={blok.description}
      label={blok.label}
      onClick={() =>
        blok.doc === 'Acceptance Letter'
          ? window.open(
            acceptanceLetterUrl,
              '_blank'
            )
          : window.open(blok.link.url, '_blank')
      }
      resource_icon={resourceIcon}
      state="resource"
      title={blok.title}
      data_name={blok.title}
    />
  );
}