import React from 'react';
import {ApplicationHeaderSa} from 'hult-toolkit';
import 'hult-toolkit/dist/style.css';
import {useAppContext} from '../../context/AppContext';
import {ISbStoryData} from '@storyblok/react';
import {useNavigate} from 'react-router-dom';
import {replaceVars} from '../../utils';

export interface IApplicationHeader {
  blok: ISbStoryData & {
    title: string;
    label: string;
  };
}

export const ApplicationHeader: React.FC<IApplicationHeader> = ({
  blok,
}) => {
  const {session, appStage} = useAppContext();
  const appState = useAppContext();
  const navigate = useNavigate();

  const program = session?.program || '';
  const programStartTerm = session?.programStartTerm || '';
  const campus = session?.campus || '';


  function handleClick() {
    navigate({
      pathname: `/question`,
      search: `?step=program-details`,
    });
  }

  return (
    <>
      {blok.label ? (
        <ApplicationHeaderSa
          button_label=''
          labels={[
            {
              label: blok.label,
            },
          ]}
          title={replaceVars(appState, blok.title)}
        />
      ) : (
        <ApplicationHeaderSa
          button_label={appStage === 'In Progress' ? 'Edit program' : ''}
          labels={[
            {
              label: program,
            },
            {
              label: programStartTerm,
            },
            {
              label: campus,
            },
          ]}
          onClick={handleClick}
          title={blok.title}
        />
      )}
    </>
  );
};