import {createGlobalStyle} from 'styled-components';
import EFCircularWebBookWoff from '../fonts/EFCircularWeb-Book.woff';
import EFCircularWebBookWoff2 from '../fonts/EFCircularWeb-Book.woff2';
import EFCircularWebBookItalicWoff from '../fonts/EFCircularWeb-BookItalic.woff';
import EFCircularWebBookItalicWoff2 from '../fonts/EFCircularWeb-BookItalic.woff2';
import EFCircularWebMediumWoff from '../fonts/EFCircularWeb-Medium.woff';
import EFCircularWebMediumWoff2 from '../fonts/EFCircularWeb-Medium.woff2';
import EFCircularWebMediumItalicWoff from '../fonts/EFCircularWeb-MediumItalic.woff';
import EFCircularWebMediumItalicWoff2 from '../fonts/EFCircularWeb-MediumItalic.woff2';
import EFCircularWebBoldWoff from '../fonts/EFCircularWeb-Bold.woff';
import EFCircularWebBoldWoff2 from '../fonts/EFCircularWeb-Bold.woff2';

interface IGlobalTheme {
  theme: any;
}

const GlobalStyle = createGlobalStyle<IGlobalTheme>`
  * {
    outline: none;
    box-sizing: border-box;
  }
  body {
    margin: 0;
    min-height: 100vh;
    position: relative;
    background: ${props => props.theme.colors.ivory};

    &.banner-active{
      padding: 0 0 84px 0;
      @media (max-width: ${props => props.theme.breakPoints.mobile}) {
        padding: 0 0 120px 0;
      }
    }
  }
  @font-face {
    font-family: ${props => props.theme.fonts.circularBook};
    src: 
      url(${EFCircularWebBookWoff2}) format('woff2'),
      url(${EFCircularWebBookWoff}) format('woff');
  }
  @font-face {
    font-family: ${props => props.theme.fonts.circularBookItalic};
    src: url(${EFCircularWebBookItalicWoff2}) format('woff2'),
      url(${EFCircularWebBookItalicWoff}) format('woff');
  }
  @font-face {
    font-family: ${props => props.theme.fonts.circularMedium};
    src: url(${EFCircularWebMediumWoff2}) format('woff2'),
      url(${EFCircularWebMediumWoff}) format('woff');
  }
  @font-face {
    font-family: ${props => props.theme.fonts.circularMediumItalic};
    src: url(${EFCircularWebMediumItalicWoff2}) format('woff2'),
      url(${EFCircularWebMediumItalicWoff}) format('woff');
  }
  @font-face {
    font-family: ${props => props.theme.fonts.circularBold};
    src: url(${EFCircularWebBoldWoff2}) format('woff2'),
      url(${EFCircularWebBoldWoff}) format('woff');
  }
  p:not("h-p"){
    max-width: 53rem;
    margin: 16px 0;
    ${props => props.theme.mixins.body}

    &:nth-of-type(1){
      margin-top: 0;
    }
    &:nth-last-of-type(1){
      margin-bottom: 0;
    }
  }
  ul:not("h-ul"), ol:not("h-ol") {
    font-weight: initial;
    ${props => props.theme.mixins.body}
  }
  em:not("h-em"), i:not("h-i"){
    font-weight: initial;
    font-family: ${props => props.theme.fonts.circularBookItalic};
  }
  strong{
    font-family: ${props => props.theme.fonts.circularMedium};
  }
  strong:not("h-strong"), b:not("h-b"){
    font-weight: initial;
    font-family: ${props => props.theme.fonts.circularMedium};
    color: ${props => props.theme.colors.black};
  }
  a{
    color: ${props => props.theme.colors.darkGrey};
  }
  a:not("h-a"){
    ${props => props.theme.mixins.anchor}
  }
`;

export {GlobalStyle};
