import React from 'react';
import {IBreadcrumbItem} from './breadcrumbItem.types';
import {StyledCrumb, StyledCrumbAnchor} from './breadcrumbItem.styles';
import {Icon} from '../../icon/icon';

export const BreadcrumbItem: React.FC<IBreadcrumbItem> = ({
  title,
  url,
  onClick,
  cursor = 'default',
}) => {
  return (
    <StyledCrumb cursor={cursor}>
      {title && title.length > 1 ? <Icon icon="arrow-right-long" /> : null}
      <StyledCrumbAnchor href={url} onClick={onClick}>
        {title}
      </StyledCrumbAnchor>
    </StyledCrumb>
  );
};
