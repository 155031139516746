import React, {useEffect, useState} from 'react';
import {
  StyledCheckValidation,
  StyledCrossValidation,
  StyledInput,
  StyledInputWrapper,
  StyledLabel,
  StyledLabelRequired,
  StyledLabelText,
} from '../inputs.styles';

import {IInput} from './input.types';
import {htmlId, moveCursorToEnd} from './helpers';
import {emptyStrChecker} from './input.patterns';

const DisplayedIcon: React.FC<{inputValid: boolean}> = ({inputValid}) => {
  if (inputValid) return <StyledCheckValidation icon="check-circle" />;
  return <StyledCrossValidation icon="times-circle" />;
};

export const Input: React.FC<IInput> = ({
  _uid,
  placeholder = '',
  name = '',
  label = '',
  type = 'text',
  required = false,
  hidden = false,
  disabled = false,
  value = '',
  onChange,
  blur,
  ref,
  loading = false,
  disableInput = false,
  disabledKeys = [],
  cursorToEnd = false,
  onClick,
  minLength,
  maxLength,
  pattern,
}) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [inputValid, setInputValid] = useState<boolean | undefined>(
    value?.length ? true : undefined
  );
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const meetsMinimum =
    minLength && inputValue && inputValue.length >= minLength;
  const isEmptyStr = emptyStrChecker(inputValue || '');

  useEffect(() => {
    onChange &&
      onChange({
        value: !inputValid ? '' : inputValue,
        valid: inputValid,
      });
  }, [inputValid, inputValue]);

  useEffect(() => {
    setEditing(false);
    setInputValue(value);
    setInputValid(value?.length ? true : undefined);
  }, [_uid]);

  useEffect(() => {
    if (!editing) {
      setInputValue(value);
      setInputValid(value?.length ? true : undefined);
    }
  }, [value]);

  useEffect(() => {
    const setIconLogic = meetsMinimum
      ? !isEmptyStr && meetsMinimum
      : !isEmptyStr;

    setShowIcon(setIconLogic);
  }, [inputValid, inputValue, onChange, meetsMinimum, isEmptyStr]);

  return (
    <StyledInputWrapper hidden={hidden}>
      <StyledLabel htmlFor={htmlId(_uid)} loading={loading}>
        <StyledLabelText>{label}</StyledLabelText>
        <StyledLabelRequired>
          {required &&
            isEmptyStr &&
            (inputValue && inputValue.length > 0 ? (
              <span>Invalid data entry</span>
            ) : (
              <span>This is required</span>
            ))}
        </StyledLabelRequired>
      </StyledLabel>

      <StyledInput valid={inputValid} loading={loading}>
        <input
          data-testid="input"
          ref={ref}
          id={htmlId(_uid)}
          name={name}
          type={type}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          value={inputValue}
          minLength={minLength}
          maxLength={maxLength}
          onBlur={blur}
          onKeyDown={e => {
            if (disableInput) {
              e.preventDefault();
            }

            if (disabledKeys.length) {
              disabledKeys.forEach(keyboardKey => {
                if (e.key === keyboardKey) {
                  e.preventDefault();
                }
              });
            }
          }}
          onChange={e => {
            const {value, validity} = e.target;
            const conditions = [validity.valid, !emptyStrChecker(value)];
            setEditing(true);
            if (pattern) {
              conditions.push(new RegExp(pattern).test(value));
            }

            const meetAllConditions = conditions.every(con => con);

            setInputValid(meetAllConditions);
            setInputValue(value);
          }}
          onClick={e => {
            if (cursorToEnd) moveCursorToEnd(htmlId(_uid));
            if (onClick) onClick(e);
          }}
        />
        {showIcon && inputValid !== undefined && (
          <DisplayedIcon inputValid={inputValid} />
        )}
      </StyledInput>
    </StyledInputWrapper>
  );
};
