import { useEffect, useState } from 'react';
import { TextInput } from '../TextInput';
import { IRecommendationUI } from './Recommendation.types';
import { checkFieldValidity, checkIfOnlySpaces } from '../QuestionInputHelpers';
import { EmailInput } from '../EmailInput';
import { IEventTarget } from '../../QuestionForm/questionForm.types';
import { StyledRecommendationWrapper } from './recommendation.styles';

export const RecommendationUI = ({
  defaultValue,
  handleChange,
}: IRecommendationUI) => {
  const [recommendationObj, setRecommendationObj] = useState(defaultValue);
  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    if (recommendationObj) {
      const checkValidity = Object.entries(recommendationObj).every(ent => {
        return !checkIfOnlySpaces(ent[1]);
      });

      handleChange({
        value: recommendationObj,
        valid: checkValidity && validEmail,
      });
    }
  }, [recommendationObj]);

  return (
    <StyledRecommendationWrapper>
      <TextInput
        label="First Name"
        value={recommendationObj?.firstName || ''}
        valid={checkFieldValidity(recommendationObj?.firstName)}
        id="firstName"
        onChange={(e: IEventTarget) => {
          setRecommendationObj({
            ...recommendationObj,
            firstName: e.target.value,
          });
        }}
      />
      <TextInput
        label="Last Name"
        value={recommendationObj?.lastName || ''}
        valid={checkFieldValidity(recommendationObj?.lastName)}
        id="lastName"
        onChange={(e: IEventTarget) => {
          setRecommendationObj({
            ...recommendationObj,
            lastName: e.target.value,
          });
        }}
      />
      <TextInput
        label="Company Name or School Name"
        value={recommendationObj?.companyName || ''}
        valid={checkFieldValidity(recommendationObj?.companyName)}
        id="companyName"
        onChange={(e: IEventTarget) => {
          setRecommendationObj({
            ...recommendationObj,
            companyName: e.target.value,
          });
        }}
      />
      <EmailInput
        label="Email"
        handleChange={(e: any) => {
          setValidEmail(e.valid);
          setRecommendationObj({
            ...recommendationObj,
            email: e.value,
          });
        }}
        questionMapping=""
        defaultValue={recommendationObj?.email}
      />
      <TextInput
        label="Position/Job Title"
        value={recommendationObj?.jobTitle || ''}
        valid={checkFieldValidity(recommendationObj?.jobTitle)}
        id="jobTitle"
        onChange={(e: IEventTarget) =>
          setRecommendationObj({
            ...recommendationObj,
            jobTitle: e.target.value,
          })
        }
      />
    </StyledRecommendationWrapper>
  );
};
