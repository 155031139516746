import styled from "styled-components";

export const StyledDatePickerDesktop = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
    display: block;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    width: 100%;
  }
`;
export const StyledDatePickerNative = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakPoints.tablet}) {
    width: 100%;
    display: block;

    & > div {
      margin: 0;
    }
  }
`;
export const StyledDatePicker = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.colors.darkGrey};
  background: ${(props) => props.theme.colors.white};
`;

export const StyledDateInput = styled.input<any>`
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 0px;
  width: 100%;
  text-align: center;

  color: ${(props) =>
    props.validity === undefined
      ? "black"
      : props.validity
      ? "#44bf56"
      : "#ee6160"};

  &[name="month"] {
    width: 30px;
  }

  &[name="day"] {
    width: 27px;
  }

  &[name="year"] {
    width: 46px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const StyledDatesInputWrapper = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
`;
