import { useState, useEffect, ReactElement, cloneElement, Children } from 'react';
import { IConditionalOption, ISessionDataType } from './conditionalOption.types';
import { StyledConditional } from './conditionalOption.styles';
import { useAppContext } from '../../context/AppContext';
import { getNewDynamicState } from './conditionalOption.helpers';

const ConditionalOption = ({
  children,
  condition_option_props,
  initState,
}: IConditionalOption) => {

  if (!condition_option_props) {
    return <>{children}</>
  }

  const {
    question_field,
    settings_field,
    value,
    result,
    condition,
    lock_message
  } = condition_option_props

  const appContext = useAppContext()
  const [dynamicState, setDynamicState] = useState<string | undefined>(initState)

  const updateDynamicState = (inputVal: string) => {

    const allValues = value?.split(',').map(str => str?.toLowerCase());
    const matchValue = allValues?.includes((inputVal)?.toLowerCase());
    const newDynamicState = getNewDynamicState({
      condition,
      matchValue,
      result,
      initState
    });

    setDynamicState(newDynamicState);
  };

  useEffect(() => {
    const appContextVal = appContext[settings_field]?.toString() || appContext[question_field]?.toString()
    const sessionVal = (appContext.session as ISessionDataType)[question_field]?.toString()
    const resValue = appContextVal || sessionVal

    updateDynamicState(resValue)
  }, [appContext, question_field, settings_field]);

  let newProps: any = {
    state: dynamicState
  }

  if (dynamicState === 'locked') {
    newProps.onClick = () => {
      if (lock_message) {
        alert(lock_message)
      }
    }
  }

  return (
    <StyledConditional hide={dynamicState}>
      {Children.map(children, child => cloneElement(child as ReactElement<any>, newProps))}
    </StyledConditional>
  );
};

export default ConditionalOption;

