import { FC, useEffect, useState } from 'react';
import { IOptions } from '../../ui/inputs/inputs.types';
import { getDatasourceEntries } from '../../../services/storyblok';
import { PicklistUI } from './PickListUI';
import {
  DependantValueDefault,
  PickListDefault,
  isSameValue,
} from '../QuestionInputHelpers';
import { IPicklist } from './PickList.types';

const emptySubmitObj = {
  value: '',
  valid: false,
  name: '',
};

export const Picklist: FC<IPicklist> = ({
  label,
  select_ds_options,
  select_blok_options,
  stepRecord,
  questionMapping,
  handleChange,
  dependentOn = '',
}) => {
  const stepRecordValue = stepRecord?.[questionMapping];
  const dependantValue = stepRecord?.[dependentOn || ''];

  const savedValue =
    typeof stepRecordValue === 'boolean'
      ? JSON.stringify(stepRecordValue)
      : stepRecordValue || '';

  const defaultVal = PickListDefault({ savedValue, questionMapping });

  const defaultOptions =
    select_blok_options && select_blok_options.length > 0
      ? select_blok_options
      : [];

  const datasourceDependant = DependantValueDefault(
    dependentOn,
    dependantValue
  );

  const [options, setOptions] = useState<IOptions[]>(defaultOptions);
  const [submitObj, setSubmitObj] = useState(emptySubmitObj);

  useEffect(() => {
    if (select_ds_options) {
      getDatasourceEntries(select_ds_options, datasourceDependant)
        .then(result => {
          setOptions(result);
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      setOptions(select_blok_options || []);
    }
  }, [select_ds_options, select_blok_options]);

  useEffect(() => {
    const resValue = submitObj?.value || defaultVal;

    const resValid =
      options.find(({ value }) => isSameValue(value, resValue)) !== undefined;

    let res = {
      value: resValue,
      valid: resValid,
      name: submitObj?.name || resValue,
    };

    handleChange(res);
  }, [options, submitObj]);

  return (
    <PicklistUI
      question_mapping={questionMapping}
      label={label}
      selectedValue={defaultVal}
      options={options}
      onChange={(e: any) => setSubmitObj(e)}
    />
  );
};
