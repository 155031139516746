import styled from 'styled-components';
import {StyledIcon} from '../../icon/icon.styles';

export const StyledCrumbAnchor = styled.a`
  ${props => props.theme.mixins.bold};
  text-decoration: none;
  white-space: nowrap;
`;

export interface IStyledCrumbProps {
  cursor: 'default' | 'pointer';
}

export const StyledCrumb = styled.div<IStyledCrumbProps>`
  display: flex;
  align-items: center;

  &:nth-of-type(1) {
    & ${StyledCrumbAnchor} {
      ${props => props.theme.mixins.body};
    }
    & ${StyledIcon} {
      display: none;
    }
  }

  ${StyledIcon} {
    padding: 0 8px;
    display: flex;
    ${props => props.theme.mixins.icon};
  }

  cursor: ${props => props.cursor};
`;
