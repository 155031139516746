import { FC, useEffect, useState } from 'react';
import { IQuestionProps } from '../../QuestionForm/questionForm.types';
import { TextInput } from '../TextInput';
import { stringToInteger, validateNumericalInput } from '../QuestionInputHelpers';

export const NumberInput: FC<IQuestionProps> = ({
  stepRecord,
  questionMapping,
  handleChange,
}) => {
  const defaultValue = stringToInteger(stepRecord?.[questionMapping]) || '';

  const [numberValue, setNumberValue] = useState<string>(defaultValue);

  useEffect(() => {
    handleChange({
      value: Number(numberValue),
      valid: validateNumericalInput(numberValue),
    });
  }, [numberValue]);

  return (
    <TextInput
      value={numberValue}
      valid={validateNumericalInput(numberValue)}
      onChange={e => {
        const inputVal = e.target.value;

        const validPattern =
          validateNumericalInput(inputVal) || inputVal === '';

        if (validPattern) {
          setNumberValue(inputVal);
        }
      }}
    />
  );
};
