import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'hult-toolkit';
import { useAppContext, useAppDispatch } from '../../../context/AppContext';
import { useCompleteApplicationStepMutation } from '../../../graphql';
import { IStep } from '../../../data/types';
import { FormstackUi } from './FormStackUI';

export const FormStack: FC<IStep> = ({
  step_name,
  questions = [],
  isPreview,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [completeApplicationStepMutation] = useCompleteApplicationStepMutation()


  const { program, programData, selectedApplication } = useAppContext();

  const [formsConfig, setFormsConfig] = useState({
    value: false,
    valid: false,
  });

  const handleSubmit = async () => {
    if (formsConfig.value) {

      await completeApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: step_name || ''
        },
        onCompleted(data) {
          dispatch({
            type: 'complete-step',
            payload: {
              guid: selectedApplication || '',
              step: step_name,
            },
          });
        },
        onError(err) {
          console.log("completeApplicationStepMutation error", err)
        }
      })

      dispatch({
        type: 'ga-event',
        payload: {
          event: 'stdapp_sectionComplete',
          content: `(3.0) - ${step_name} - ${program}`,
        },
      });

      navigate('/');
    }
  };

  const correctForm = questions?.find(({ content }) => {

    const {
      dependent_on,
      dependant_condition,
      dependent_type,
      dependent_value
    } = content

    const allQuestionFormTypes = dependent_value
      ?.split(',')
      .map(str => str.toUpperCase());

    //@ts-ignore - complains about using string to index
    const savedVal = programData?.[dependent_on]

    if (dependant_condition === 'eq' && dependent_type === 'show') {
      return allQuestionFormTypes?.includes(savedVal.toUpperCase())
    }

    return false
  });

  const displayedDec = questions.length === 1 ? questions[0] : correctForm;

  if (!displayedDec) {
    return <h1>Formstack Error</h1>;
  }

  const { display_title, formstack_form_link, question_mapping } =
    displayedDec?.content;

  return (
    <>
      <FormstackUi
        display_title={display_title}
        question_mapping={question_mapping}
        formstack_form_link={formstack_form_link}
        handleChange={e => setFormsConfig(e)}
      />
      <Button
        label="Continue"
        Wrapper="button"
        variant={formsConfig.value ? 'primary' : 'disabled'}
        WrapperProps={{
          onClick: handleSubmit,
        }}
      />
    </>
  );
};
