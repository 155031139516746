import styled from 'styled-components';
import {StyledInput, StyledCheckValidation} from '../inputs.styles';
import {Icon} from '../../icon/icon';

export const StyledLookup = styled.div`
  width: 100%;
  position: relative;

  & ${StyledInput} {
    z-index: 1;
  }
`;

export const StyledIconSearch = styled(Icon)`
  padding: 16px;
`;

export const StyledDropDown = styled.div<any>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: ${props => props.width || '100%'};
  background: ${props => props.theme.colors.white};
  border: 2px solid ${props => props.theme.colors.darkGrey};
  margin-top: -3px;
  border-radius: 0 0 4px 4px;
  max-height: 240px;
  overflow-y: scroll;
`;

export const StyledOption = styled.div`
  width: 100%;
  padding: 16px;
  cursor: pointer;
  z-index: 2;
  border-top: 2px solid ${props => props.theme.colors.darkGrey};
  ${props => props.theme.mixins.body}
  color: ${props => props.theme.colors.darkGrey};

  &:nth-of-type(1) {
    border-top: 0;
  }

  &:hover {
    background: ${props => props.theme.colors.black5};
  }

  & ${StyledCheckValidation} {
    margin: 0 1rem 0 0;
    @media (max-width: 1024px) {
      margin: 0 1rem 0 0;
    }
  }
`;
export const StyledSelected = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
`;

export const StyledSelectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledRequiredContainer = styled.div``;

export const StyledSelectContainer = styled.div`
  display: flex;
  background: ${props => props.theme.colors.black5};
  border-radius: 4px;
  align-items: center;
  padding: 8px 14px;

  & p {
    color: ${props => props.theme.colors.darkGrey};
    font-family: ${props => props.theme.fonts.circularBold};
    white-space: nowrap;
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & span {
    & svg {
      padding-left: 8px;
      color: ${props => props.theme.colors.red};
      height: 14px;
      width: 14px;
      max-height: 14px;
      max-width: 14px;
      margin: 0;
      @media (max-width: 1024px) {
        margin: 0;
      }
    }
  }
`;
