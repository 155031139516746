import { render } from 'storyblok-rich-text-react-renderer';
import { useAppContext, useAppDispatch } from '../../context/AppContext';
import { useSettingsContext } from '../../context/SettingsContext';
import { IStep } from '../../data/types';
import {
  useCompleteApplicationStepMutation,
  useDeleteApplicationDocumentStepMutation,
  useGetStudentDocumentV2Query,
  useUpdateApplicationStepMutation,
} from '../../graphql';
import { BackButton } from '../BackButton';
import {
  StyledButtonContainer,
  StyledQuestionWrapper,
} from '../QuestionForm/questionForm.styles';
import { StyledDocumentUpload, StyledQuestion, StyledAlertWrapper } from './steps.styles';
import { HTMLAttributes, useEffect, useState } from 'react';
import { DocumentUpload } from '../ui/documentUpload';
import { IDocumentContainerProps, IDocumentType } from './steps.types';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AlertSa, Button } from 'hult-toolkit';
import { variantMapping } from '../../utils/variantMapping';

const DocumentContainer: React.FunctionComponent<IDocumentContainerProps> = ({
  display_title,
  description,
  documentData,
  changeHandler,
  viewDocument,
  handleDelete,
  loading,
}) => {
  const [documentState, setDocumentState] = useState<
    'default' | 'uploaded' | 'loading'
  >('loading');
  const [selectedFileName, setSelectedFileName] = useState<string>();
  const [uploadingFile, setUploadingFile] = useState(false);

  useEffect(() => {
    if (loading || uploadingFile) return setDocumentState('loading');
    if (documentData && documentData.filename) {
      setSelectedFileName(documentData.filename);
      setDocumentState('uploaded');
    } else {
      setDocumentState('default');
    }
  }, [loading, uploadingFile, documentData]);

  return (
    <StyledDocumentUpload>
      <DocumentUpload
        state={documentState}
        title={display_title}
        accept=".pdf,.xls,.xlsx,.docx,.doc,.jpg,.png"
        copy={description}
        filename={selectedFileName}
        onChange={changeHandler}
        viewHandler={viewDocument}
        deleteHandler={handleDelete}
      />
    </StyledDocumentUpload>
  );
};

export const StepDocument: React.FC<IStep> = ({ step_name, questions }) => {
  const { selectedApplication } = useAppContext();
  const { errorMessage } = useSettingsContext();
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<IDocumentType[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [specialCharactersError, setSpecialCharactersError] = useState<boolean>(false);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);

  const question = questions && questions[0] ? questions[0].content : null;

  const [updateApplicationStepMutation] = useUpdateApplicationStepMutation();
  const [deleteApplicationDocumentStepMutation] =
    useDeleteApplicationDocumentStepMutation();
  const [completeApplicationStepMutation] =
    useCompleteApplicationStepMutation();

  const { loading, error } = useGetStudentDocumentV2Query({
    variables: {
      guid: selectedApplication || '',
      step: step_name || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: !selectedApplication || !step_name,
    onCompleted({ getApplicationDocuments }) {
      if (getApplicationDocuments) {
        setDocuments(getApplicationDocuments as IDocumentType[]);
      }
    },
    onError(error) {
      console.log("useGetStudentDocumentV2Query error", error)
    }
  });

  if (error) {
    console.error(error);
    return (
      <StyledQuestion>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
            title={errorMessage.title || ''}
          />
        )}
      </StyledQuestion>
    );
  }

  const containsSpecialCharacters = (filename: string): boolean =>
    /[&"'?<>#{}%~/\\]/.test(filename);

  const changeHandler = (event: any, docData: IDocumentType) => {
    const selectedFile = event.target.files[0];
    let hasError = false;

    if (selectedFile && containsSpecialCharacters(selectedFile.name)) {
      setSpecialCharactersError(true);
      hasError = true;
    } else {
      setSpecialCharactersError(false);
    }

    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
    if (selectedFile && selectedFile.size > maxFileSize) {
      setFileSizeError(true);
      hasError = true;
    } else {
      setFileSizeError(false);
    }

    if (hasError) {
      return;
    }
    if (selectedFile && docData.uploadUrl) {
      const formData = new FormData();

      formData.append('File', selectedFile);

      axios
        .put(docData.uploadUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile.type,
          },
        })
        .then(function (result) {
          updateApplicationStepMutation({
            variables: {
              guid: selectedApplication || '',
              step: docData?.documentData?.step || '',
              filename: selectedFile.name,
              contentType: selectedFile.type,
            },
            refetchQueries: ['GetStudentDocumentV2'],
            onCompleted() {
              completeApplicationStepMutation({
                variables: {
                  guid: selectedApplication || '',
                  step: docData.documentData?.step || '',
                  documentData: {
                    filename: selectedFile.name,
                    contentType: selectedFile.type,
                    url: docData.url || '',
                  },
                },
                onCompleted() {
                  setSpecialCharactersError(false)
                  setFileSizeError(false);
                  dispatch({
                    type: 'complete-step',
                    payload: {
                      guid: selectedApplication || '',
                      step: docData?.documentData?.step,
                      documentData: {
                        filename: selectedFile.name,
                        contentType: selectedFile.type,
                        url: docData.url,
                      },
                    },
                  });
                },
                onError(error) {
                  console.log('completeApplicationStepMutation error', error)
                },
              });
            },
            onError(error) {
              console.log('updateApplicationStepMutation error', error)
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const handleDelete = (docData: IDocumentType) => {
    setShowLoader(true);
    if (docData.deleteUrl && docData.documentData?.contentType) {
      axios
        .delete(docData.deleteUrl, {
          headers: {
            'Content-Type': docData.documentData.contentType,
          },
        })
        .then(() => {
          deleteApplicationDocumentStepMutation({
            variables: {
              guid: selectedApplication || '',
              step: docData?.documentData?.step || '',
            },
            refetchQueries: ['GetStudentDocumentV2'],
            onCompleted() {
              dispatch({
                type: 'uncomplete-step',
                payload: {
                  guid: selectedApplication || '',
                  step: step_name,
                },
              });
              setShowLoader(false);
            },
            onError(error) {
              console.log('deleteApplicationDocumentStepMutation error', error)
            }
          });
        });
    }
  };

  const viewDocument = (docData: IDocumentType) => {
    if (window && docData.url) {
      const nTab = window.open(docData.url, '_blank');
      if (nTab) {
        nTab.focus();
      }
    }
  };

  return (
    <StyledQuestion>
      <BackButton />
      <StyledQuestionWrapper>
        {specialCharactersError && (
          <StyledAlertWrapper>
            <AlertSa
              title={''}
              desc={`File names can't contain the following characters: ' & " ? <> # % ~ / \. Please remove the special characters and upload again.`}
              icon={'times'}
              icon_color={'red'}
            />
          </StyledAlertWrapper>
        )}
        {fileSizeError && (
          <StyledAlertWrapper>
            <AlertSa
              title={''}
              desc={`The file exceeds the 5MB size limit. Please reduce the file size and try again.`}
              icon={'times'}
              icon_color={'red'}
            />
          </StyledAlertWrapper>
        )}
        {documents.map((doc, idx) => {
          if (doc?.documentData) {
            if (showLoader)
              return (
                <DocumentContainer key={doc.documentData.step} loading={true} />
              );
            return (
              <DocumentContainer
                key={doc.documentData.step}
                display_title={idx === 0 ? question?.display_title : undefined}
                description={idx === 0 ? question?.description : undefined}
                documentData={doc.documentData}
                changeHandler={event => changeHandler(event, doc)}
                viewDocument={() => viewDocument(doc)}
                handleDelete={() => handleDelete(doc)}
                loading={loading}
              />
            );
          }
        })}
        <StyledButtonContainer>
          {loading && (
            <Button
              icon={{
                animate: 'spin',
                library: 'solid',
                name: 'spinner',
              }}
              label="Loading"
              variant="disabled"
            />
          )}
          {!loading && (
            <Button
              Wrapper="button"
              WrapperProps={
                {
                  onClick: () => {
                    navigate(-1);
                  },
                  disabled: documents.length <= 1 ? 'disabled' : '',
                } as HTMLAttributes<HTMLButtonElement>
              }
              variant={documents.length <= 1 ? 'disabled' : 'primary'}
              label="Continue"
              icon={{
                animate: '',
                library: 'solid',
                name: 'arrow-right',
              }}
            />
          )}
        </StyledButtonContainer>
      </StyledQuestionWrapper>
    </StyledQuestion>
  );
};
