import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppContext} from '../../context/AppContext';
import {Breadcrumbs} from '../BreadCrumb';
import {IStep} from '../../data/types';
import {IStepsProps} from './steps.types';
import {StepStandard} from './stepStandard';
import {StepMultiEntry} from './stepMultiEntry';
import {StepPayment} from './stepPayment';
import {StepDocument} from './StepDocument';
import {FormStack} from '../QuestionInputs/FormStack';
import {useUpdateLastVisitMutation} from '../../graphql';
import {TermsAndConditions} from '../QuestionInputs/TermsAndConditions';
import {Layout, SectionSa} from 'hult-toolkit';

const StepContainer: React.FC<{
  content: IStep;
  isPreview?: boolean;
}> = ({content, isPreview}) => {
  const {step_type} = content;
  const {selectedApplication} = useAppContext();

  const location = useLocation();
  const [updateLastVisitMutation] = useUpdateLastVisitMutation();

  useEffect(() => {
    if (location?.pathname) {
      updateLastVisitMutation({
        variables: {
          guid: selectedApplication || '',
          page: `${location?.pathname}${location?.search || ''}`,
        },
      });
    }
  }, [location]);

  switch (step_type) {
    case 'document':
      return <StepDocument {...content} isPreview={isPreview} />;
    case 'multi-record':
      return <StepMultiEntry {...content} isPreview={isPreview} />;
    case 'payment':
      return <StepPayment {...content} isPreview={isPreview} />;
    case 'terms-and-conditions':
      return <TermsAndConditions {...content} isPreview={isPreview} />;
    case 'formstack':
      return <FormStack {...content} isPreview={isPreview} />;
    case 'standard':
      return <StepStandard {...content} isPreview={isPreview} />;
    default:
      return <>Missing</>;
  }
};

export const Step: React.FC<IStepsProps> = ({blok, isPreview}) => {
  const {display_name} = blok;

  return (
    <Layout>
      <Breadcrumbs title={display_name || ''} />
      <SectionSa>
        <StepContainer content={blok} isPreview={isPreview} />
      </SectionSa>
    </Layout>
  );
};
