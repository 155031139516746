import React, {useEffect, useRef} from 'react';
import {ISbStoryData} from '@storyblok/react';
import {sbLinkType} from '../../data/types';
import {useAppContext} from '../../context/AppContext';
import {replaceVars} from '../../utils';
import {BannerSa} from 'hult-toolkit';
import {transformMarkdown} from '../../utils/markdownHelper';
import {useGetAcceptanceLetterV2Query} from '../../graphql';

export interface IHeroButton {
  variant:
    | 'ghost'
    | 'primary'
    | 'secondary'
    | 'secondary_alt'
    | 'tertiary'
    | 'tertiary_alt'
    | 'ghost_alt'
    | 'disabled'
    | 'valid'
    | 'invalid';
  label: string;
  icon: string;
  link: sbLinkType;
}

export interface IHero {
  blok: ISbStoryData & {
    title: string;
    text: string;
    buttons: Array<IHeroButton>;
    tag: Array<{
      label: string;
      color: 'blue' | 'green' | 'red';
      icon: string;
      icon_color: 'yellow' | 'white';
    }>;
  };
}

export const Hero: React.FC<IHero> = ({blok}) => {
  const appState = useAppContext();
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const {acceptanceLetter, selectedApplication, appStage} = useAppContext();
  const acceptanceLetterId = acceptanceLetter?.acceptanceLetter;
  const acceptanceLetterConditionsMet = acceptanceLetter?.notifiedDate;

  useEffect(() => {
    const bannerP = bannerRef.current?.querySelector('p') as HTMLDivElement;
    if (bannerP) {
      const transformedText = transformMarkdown(
        replaceVars(appState, blok.text)
      ).replace(/<\/?span[^>]*>/g, '');
      bannerP.innerHTML = transformedText;
    }
  }, [blok.text]);

  const {loading, data, error} = useGetAcceptanceLetterV2Query({
    variables: {
      guid: selectedApplication || '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 360000,
    skip: !acceptanceLetterConditionsMet,
  });

  const heroButtons = blok.buttons.map(button => ({
    label: button.label,
    variant: button.variant,
    icon: {
      name: button.icon,
    },
    Wrapper: 'button',
    WrapperProps: {
      onClick: () => {
        window.open(button.link.url), '_blank';
      },
    },
  }));

  if (appStage === 'Accepted' || appStage === 'Conditionally Accepted') {
    if (acceptanceLetterConditionsMet && acceptanceLetterId) {
      heroButtons.push({
        label: 'View Letter',
        variant: 'secondary',
        Wrapper: 'button',
        icon: {
          name: 'arrow-up-right-from-square',
        },
        WrapperProps: {
          onClick: () => {
            window.open(
              `${process.env.REACT_APP_ACCEPTANCE_URL}=${acceptanceLetterId}`,
              '_blank'
            );
          },
        },
      });
    } else if (
      acceptanceLetterConditionsMet &&
      data?.getApplicationAcceptanceLetter?.acceptanceLetterUrl
    ) {
      heroButtons.push({
        label: 'View Letter',
        variant: 'secondary',
        Wrapper: 'button',
        icon: {
          name: 'arrow-up-right-from-square',
        },
        WrapperProps: {
          onClick: () => {
            window.open(
              data?.getApplicationAcceptanceLetter?.acceptanceLetterUrl,
              '_blank'
            );
          },
        },
      });
    }
  }

  const tag = blok.tag && blok.tag.length > 0 ? blok.tag[0] : null;

  return (
    <div ref={bannerRef}>
      <BannerSa
        buttons={heroButtons}
        desc={replaceVars(appState, blok.text)}
        tag={
          tag
            ? [
                {
                  label: tag.label,
                  color: tag.color,
                  icon: tag.icon,
                  icon_color: tag.icon_color,
                },
              ]
            : undefined
        }
        title={replaceVars(appState, blok.title)}
      />
    </div>
  );
};
