import { FC, useEffect, useState } from 'react';
import { TextInput } from '../TextInput';
import { checkEmailValidity } from '../QuestionInputHelpers';
import { IEmailValidity, IEmailInput } from './EmailInput.types';
import { useAppContext } from '../../../context/AppContext';

export const EmailInput: FC<IEmailInput> = ({
  stepRecord,
  questionMapping,
  handleChange,
  label,
  defaultValue,
}) => {
  const defaultEmail = defaultValue || stepRecord?.[questionMapping] || '';

  const [emailValue, setUrlValue] = useState(defaultEmail);
  const [emailValid, setUrlValid] = useState<IEmailValidity>({
    message: `${defaultEmail !== ''}`,
    valid: defaultEmail !== '',
  });

  const { email } = useAppContext();

  useEffect(() => {
    handleChange({
      value: emailValid.message === 'invalid' ? '' : emailValue,
      valid: emailValid.valid,
    });
  }, [emailValue]);

  const emailValidity =
    emailValid.message === 'invalid' ? false : emailValid.valid || undefined;

  const errorMessage =
    emailValid.message === 'invalid'
      ? "You can't use your own email address"
      : '';

  return (
    <TextInput
      value={emailValue}
      valid={emailValidity}
      errorMessage={errorMessage}
      label={label}
      onChange={e => {
        const inputVal = e.target.value;

        const validityObj = checkEmailValidity({
          input: inputVal,
          questionMapping,
          userEmail: email || '',
        });

        setUrlValue(inputVal);
        setUrlValid(validityObj);
      }}
    />
  );
};
