import {useNavigate} from 'react-router-dom';
import {IBackLink} from './backButton.types';
import {useQuery} from '../../utils';

import {ISbStoryData} from '@storyblok/react';
import {ISbQuestion} from '../../data/types';
import {Button} from 'hult-toolkit';

export const BackButton: React.FC<IBackLink> = ({
  loading = false,
  questions,
  stepRecord,
}) => {
  const navigate = useNavigate();
  const query = useQuery();

  function backNav(questions: ISbStoryData<ISbQuestion>[] | undefined) {
    const stepType = query.get('step');
    const editingIndex = query.get('editing');
    const qIndex = query.get('q');
    const prevIndex = Number(qIndex) - 1;
    const stepParam = `/question?step=${stepType}`;

    const editingParam =
      editingIndex !== null ? `&editing=${editingIndex}` : '';
    let newIndex = Number(qIndex) - 1;

    if (questions) {
      const prevQuestionContent = questions[prevIndex]?.content;
      const isHideCondition = prevQuestionContent?.dependent_type === 'hide';
      const stepRecordVal =
        stepRecord?.[prevQuestionContent?.question_mapping || ''];

      if (
        isHideCondition &&
        prevQuestionContent?.dependent_value === stepRecordVal
      ) {
        newIndex -= 1;
      }
    }

    const questionParam = `&q=${newIndex}`;
    const prevQuestionUrl = `${stepParam}${editingParam}${questionParam}`;

    switch (stepType) {
      case 'education':
      case 'employment':
        if (qIndex === null) {
          navigate('/');
        } else {
          navigate(qIndex === '0' ? stepParam : prevQuestionUrl);
        }
        return;
      case 'terms-and-conditions':
        navigate('/');
        return;
      default:
        navigate(
          qIndex === '0' || qIndex === null
            ? '/'
            : `${stepParam}${editingParam}${questionParam}`
        );
        return;
    }
  }

  return loading ? (
    <Button
      icon={{
        animate: 'spin',
        library: 'solid',
        name: 'spinner',
      }}
      label="Loading"
      size="small"
      variant="disabled"
    />
  ) : (
    <Button
      label="Back"
      icon={{
        animate: '',
        library: 'solid',
        name: 'arrow-left',
      }}
      icon_reverse='true'
      size="small"
      Wrapper="button"
      variant={'ghost'}
      WrapperProps={{
        onClick: () => {
          backNav(questions);
        },
      }}
    />
  );
};
