import { useState } from 'react';
import { IStep, IStudentType } from '../../data/types';
import {
  useCreateApplicationMultiStepMutation,
  useDeleteApplicationStepMutation,
  useGetApplicationMultiStepQuery,
} from '../../graphql';
import {useQuery} from '../../utils';
import {StyledQuestion} from './steps.styles';
import {useAppContext, useAppDispatch} from '../../context/AppContext';
import {StepsCard} from '../StepsCard';
import {StepGroup} from '../ui/stepGroup';
import {render} from 'storyblok-rich-text-react-renderer';
import {useSettingsContext} from '../../context/SettingsContext';
import {
  MultiRecordEducationCard,
  MultiRecordEmploymentCard,
  MultiRecordNewCard,
} from '../MultiRecordSteps';
import { useNavigate, useLocation } from 'react-router-dom';
import { BackButton } from '../BackButton';
import { MultiRecordParentCard } from '../MultiRecordSteps/MultiRecordParentCard';
import { AlertSa, Grid } from 'hult-toolkit';
import { variantMapping } from '../../utils/variantMapping';
import { MultiStepStandard } from './multiStepStandard';

interface IStepMultiEntryContainer {
  display_name?: string;
  display_description?: string;
  children?: React.ReactNode;
  handleNew?: Function;
}

const StepMultiEntryContainer: React.FC<IStepMultiEntryContainer> = ({
  display_name,
  display_description,
  children,
  handleNew,
}) => {
  return (
    <StyledQuestion>
      <BackButton />
      <h2>{display_name}</h2>
      <p>{display_description}</p>
      <Grid column={2}>
        {children}
        <MultiRecordNewCard
          label={`Add ${display_name}`}
          onClick={() => handleNew && handleNew()}
        />
      </Grid>
    </StyledQuestion>
  );
};

export const StepMultiEntry: React.FC<IStep> = props => {
  const { display_name, display_description, step_name, isPreview, questions } = props;

  const {selectedApplication, email} = useAppContext();
  const {errorMessage} = useSettingsContext();
  const dispatch = useAppDispatch();

  const [stepRecord, setStepRecord] = useState<(IStudentType | null)[]>([]);
  const [loader, setLoader] = useState(false);

  const [createApplicationMultiStepMutation] =
    useCreateApplicationMultiStepMutation();
  const [deleteApplicationStepMutation] = useDeleteApplicationStepMutation();

  const navigate = useNavigate();
  const { search } = useLocation();

  const query = useQuery();
  const editing = query.get('editing');

  const {loading, error} = useGetApplicationMultiStepQuery({
    variables: {
      guid: selectedApplication || '',
      step: step_name || '',
    },
    fetchPolicy: 'cache-and-network',
    skip: isPreview || !email || !step_name,
    onCompleted(data) {
      if (data?.getApplicationMultiStep) {
        setStepRecord(data?.getApplicationMultiStep);
      }
    },
    onError(error) {
      setLoader(false);
      console.error("useGetApplicationMultiStepQuery", error);
    },
  });

  const handleNew = () => {
    setLoader(true);
    if (email && step_name)
      createApplicationMultiStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: step_name || '',
        },
        refetchQueries: ['GetApplicationMultiStep'],
        onCompleted({createApplicationMultiStep}) {
          setLoader(false);

          navigate({
            pathname: `/question`,
            search: `${search}&editing=${createApplicationMultiStep?.stepId}`,
          });
        },
        onError(error) {
          setLoader(false);
          console.error("createApplicationMultiStepMutation error", error);
        },
      });
  };

  const handleDelete = (step: string) => {
    setLoader(true);

    if (selectedApplication)
      deleteApplicationStepMutation({
        variables: {
          guid: selectedApplication || '',
          step: step,
        },
        refetchQueries: ['GetApplicationMultiStep'],
        onCompleted(data) {
          setLoader(false);
          dispatch({
            type: 'uncomplete-step',
            payload: {
              step: step
            },
          });
        },
        onError(error) {
          console.error("deleteApplicationStepMutation error", { error });
          setLoader(false);
        },
      });
  };

  const handleEdit = (step: string) => {
    navigate({
      pathname: `/question`,
      search: `${search}&editing=${step}`,
    });
  };

  function checkComplete(question: string[], record: Record<string, any>): boolean {
    if (question.length === 0 || Object.keys(record).length === 0) {
      return false;
    }

    const isHighSchool = record['educationDegree'] === 'High School';
    const isCurrentRole = record['employmentCurrentRole'];

    const allKeysMatched = question.every((element: string) => {
      if (record.hasOwnProperty(element)) {
        if (isHighSchool && (element === 'educationSpeciality')) {
          return true;
        } else if (isCurrentRole && element === 'employmentEndDate') {
          return true;
        } else {
          return record[element] !== null && record[element] !== undefined;
        }
      }
      return false;
    });

    return allKeysMatched;
  }

  const questionsMapping = questions?.map(q => q.content.question_mapping) as string[];

  if (loading || loader) {
    return (
      <StepGroup>
        <StepsCard name="loading" state="loading" />
        <StepsCard name="loading" state="loading" />
      </StepGroup>
    );
  }

  if (error) {
    console.error(error);
    return (
      <StyledQuestion>
        {errorMessage && (
          <AlertSa
            desc={render(errorMessage.desc)}
            icon={variantMapping[errorMessage.variant].icon}
            icon_color={variantMapping[errorMessage?.variant].icon_color}
            title={errorMessage.title || ''}
          />
        )}
      </StyledQuestion>
    );
  }

  switch (true) {
    case !!editing:
      return (
        <MultiStepStandard
          {...props}
          display_name=""
          display_description=""
          step_category_name={step_name || ''}
          step_name={query.get('editing') || ''}
        />
      );
    case step_name === 'education':
      return (
        <StepMultiEntryContainer
          display_name={display_name}
          display_description={display_description}
          handleNew={handleNew}
        >
          {stepRecord.map(item => {
            if (item && item.step !== step_name) {
              return (
                <MultiRecordEducationCard
                  key={`multi-record-card-${item.step}`}
                  title={item.educationDegree || ''}
                  step={item.step || ''}
                  school={item.school || ''}
                  startDate={new Date(item.educationStartDate || '')}
                  endDate={new Date(item.educationEndDate || '')}
                  onClickDelete={() => handleDelete(item.step || '')}
                  onClickEdit={() => handleEdit(item.step || '')}
                  complete={checkComplete(questionsMapping, item)}
                />
              );
            }
          })}
        </StepMultiEntryContainer>
      );
    case step_name === 'employment':
      return (
        <StepMultiEntryContainer
          display_name={display_name}
          display_description={display_description}
          handleNew={handleNew}
        >
          {stepRecord.map(item => {
            if (item && item.step !== step_name) {
              return (
                <MultiRecordEmploymentCard
                  key={`multi-record-card-${item.step}`}
                  title={item?.employmentJobTitle || ''}
                  employmentCurrentRole={!!item.employmentCurrentRole}
                  employmentStartDate={new Date(item.employmentStartDate || '')}
                  employmentEndDate={new Date(item.employmentEndDate || '')}
                  employmentCompanyName={item.employmentCompanyName || ''}
                  onClickDelete={() => handleDelete(item.step || '')}
                  onClickEdit={() => handleEdit(item.step || '')}
                  complete={checkComplete(questionsMapping, item)}
                />
              );
            }
          })}
        </StepMultiEntryContainer>
      );
    case step_name === 'parent':
      return (
        <StepMultiEntryContainer
          display_name={display_name}
          display_description={display_description}
          handleNew={handleNew}
        >
          {stepRecord.map(item => {
            if (item && item.step !== step_name) {
              return (
                <MultiRecordParentCard
                  key={`multi-record-card-${item.step}`}
                  parentRelationship={item.parentRelationship || ''}
                  parentFirstName={item.parentFirstName || ''}
                  parentLastName={item.parentLastName || ''}
                  parentEmail={item.parentEmail || ''}
                  onClickDelete={() => handleDelete(item.step || '')}
                  onClickEdit={() => handleEdit(item.step || '')}
                  complete={checkComplete(questionsMapping, item)}
                />
              );
            }
          })}
        </StepMultiEntryContainer>
      );
    default:
      return <></>;
  }
};
