import styled from "styled-components";
import { StyledButton } from "../button/button.styles";

export interface IStyledDocumentUpload {
  state?: "default" | "loading" | "uploaded";
}

export const StyledDocumentUpload = styled.div<IStyledDocumentUpload>`
  width: 100%;

  ${(props) =>
    props.state === "loading" &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
    box-shadow: none;
  `}
`;

export const StyledDocumetDetails = styled.div``;

export const StyledTitle = styled.p<IStyledDocumentUpload>`
  ${(props) => props.theme.mixins.heading}

  ${(props) =>
    props.state === "loading" &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
  `}
`;

export const StyledCopy = styled.p<IStyledDocumentUpload>`
padding-bottom: 24px;

  ${(props) =>
    props.state === "loading" &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
  `}
`;

export const StyledFilename = styled.div`
  font-family: ${props => props.theme.fonts.circularMedium};
  padding: 14px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > p {
    margin: 0;

    &:first-of-type {
      color: ${props => props.theme.colors.black};
    }
  }

  @media (max-width: ${props => props.theme.breakPoints.mobile}) {
    padding-top: 0;
  }
`;

export const StyledError = styled.p`
  color: ${props => props.theme.colors.red};
`;

export const StyledOptions = styled.div<IStyledDocumentUpload>`
  display: flex;
  gap: 20px;

  & ${StyledButton} {
    &:nth-last-of-type(1) {
      & button {
        color: ${(props) => props.theme.colors.red};
        border-color: ${(props) => props.theme.colors.red};
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}) {
    flex-direction: column;
  }
`;

export const StyledDocumentContainer = styled.div<IStyledDocumentUpload>`
  padding: 24px;
  display: flex;
  /* margin-top: 24px; */
  position: relative;

  ${(props) =>
    props.state === "uploaded"
      ? `
    border: solid 2px black;
    justify-content: space-between;
    align-items: center;
    `
      : `
    justify-content: center;
    border: dashed 2px black;
    `}
  ${(props) =>
    props.state === "loading" &&
    `
    ${props.theme.mixins.keyframeAnimation}
    ${props.theme.mixins.elementAnimation}
    border: none;
    `};

  & ${StyledButton} {
    ${(props) => props.state === "loading" && `opacity: 0;`}
  }
`;
export const StyledButtonInput = styled.input<IStyledDocumentUpload>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;
